import {loginCookie} from '@/helpers/loginCookie';
import VueCookies from 'vue-cookies';

const state = {
    token: loginCookie.getAuthToken(VueCookies) || null,
}

const getters = {
    isAuth: state => !!state.token,
}

const actions = {
    async loginUser({commit}, token ){
        commit('SET_TOKEN', token);
        loginCookie.setAuthToken(VueCookies, token);
    },
    async registerUser({ commit }, token){
        commit('SET_TOKEN', token);
        loginCookie.setAuthToken(VueCookies , token);
    },
    async logoutUser({commit} ){
        loginCookie.removeAuthToken(VueCookies);
        commit('LOGOUT_USER');

    }
}

const mutations = {
    SET_TOKEN(state, token){
        state.token = token;
    },
    LOGOUT_USER(state){
        state.token = null;
    }
}

export default{
    state,
    getters,
    actions,
    mutations,
    namespaced: true
}