import contentDataMixin from "@/mixins/contentData";
import routes from "@/router/routes";

export default {
    mixins: [contentDataMixin],

    props: {
        contentDataWrapperProp: {
            type: String,
            required: true
        },
        contentDataProp: {
            type: String,
            default: 'exercise'
        },
        contentErrorDataProp: {
            type: String,
            default: 'exerciseError'
        },
    },
    data() {
        return {




            wantToCloseExerciseArabic: 'هل تريد غلق التمرين',
            wantToCloseExerciseEnglish: 'Do You Want To Close Exercise',
            wantToCloseExamArabic: 'هل تريد غلق الامتحان',
            wantToCloseExamEnglish: 'Do You Want To Close Exam',

            continueExamOrExerciseTextEnglish: 'Continue',
            continueExamOrExerciseTextArabic: 'استكمال',

            closeExerciseArabic: 'اغلاق التمرين',
            closeExerciseEnglish: 'Close Exercise',
            closeExamArabic: 'اغلاق الامتحان',
            closeExamEnglish: 'Close Exam',


            submitExerciseArabic: 'هل تريد تسليم التمرين',
            submitExerciseEnglish: 'Do You Want To Submit Exercise',
            submitExamArabic: 'هل تريد تسليم الامتحان',
            submitExamEnglish: 'Do You Want To Submit Exam',

            submitConfirmExamOrExerciseTextEnglish: 'Yes',
            submitConfirmExamOrExerciseTextArabic: 'نعم',


            submitDeclineExerciseArabic: 'رجوع للتمرين',
            submitDeclineExerciseEnglish: 'Back To Exercise',
            submitDeclineExamArabic: 'رجوع للامتحان',
            submitDeclineExamEnglish: 'Back To Exam',

            correctAnswerTextArabic: 'اجابتك صحيحة',
            correctAnswerTextEnglish: 'Your Answer Is Correct',
            wrongAnswerTextArabic: 'اجابتك غير صحيحة',
            wrongAnswerTextEnglish: 'Your Answer Is Wrong',

            explainAnswerTextArabic: 'تفسير الاجابة',
            explainAnswerTextEnglish: 'Explanation',

            yourCorrectAnswerTextArabic: 'الاجابة الصحيحة هى',
            yourCorrectAnswerTextEnglish: 'Correct Answer Is',

            checkAnswerTextArabic: 'تصحيح',
            checkAnswerTextEnglish: 'Check',

            nextQuestionTextArabic: 'التالى',
            nextQuestionTextEnglish: 'Next',

            submitExerciseTextArabic: 'تسليم',
            submitExerciseTextEnglish: 'Submit',

            resultGradeTextArabic: 'الدرجة',
            resultGradeTextEnglish: 'Grade',
            resultTimeTextArabic: 'وقتك للحل',
            resultTimeTextEnglish: 'Time',

            examButtonEnglishText: 'Submit Exam',
            examButtonArabicText: 'تسليم الامتحان',

            mustAnswerAllQuestionsEnglishText: 'Please Answer All Questions First',
            mustAnswerAllQuestionsArabicText: 'يجب الاجابة على جميع الاسئلة اولا'
        }
    },
    computed: {
        contentDataWrapper() {
            return this.$props[this.contentDataWrapperProp] || this.$data[this.contentDataWrapperProp] || {};
        },

        wantToCloseExamOrExerciseText() {
            if (this.isExam) {
                return this.direction === 'LTR' ? this.wantToCloseExamEnglish : this.wantToCloseExamArabic;
            } else {
                return this.direction === 'LTR' ? this.wantToCloseExerciseEnglish : this.wantToCloseExerciseArabic;
            }
        },
        closeExamOrExerciseText() {
            if (this.isExam) {
                return this.direction === 'LTR' ? this.closeExamEnglish : this.closeExamArabic;
            } else {
                return this.direction === 'LTR' ? this.closeExerciseEnglish : this.closeExerciseArabic;
            }
        },
        continueExamOrExerciseText() {
            if (this.direction === 'LTR')
                return this.continueExamOrExerciseTextEnglish;
            else
                return this.continueExamOrExerciseTextArabic;
        },

        submitExamOrExerciseText() {
            if (this.isExam) {
                return this.direction === 'LTR' ? this.submitExamEnglish : this.submitExamArabic;
            } else {
                return this.direction === 'LTR' ? this.submitExerciseEnglish : this.submitExerciseArabic;
            }
        },
        mustAnswerAllQuestionsText() {
            return this.direction === 'LTR' ? this.mustAnswerAllQuestionsEnglishText : this.mustAnswerAllQuestionsArabicText;
        },
        submitConfirmExamOrExerciseText() {
            if (this.direction === 'LTR')
                return this.submitConfirmExamOrExerciseTextEnglish;
            else
                return this.submitConfirmExamOrExerciseTextArabic;
        },

        submitDeclineExamOrExerciseText() {
            if (this.isExam) {
                return this.direction === 'LTR' ? this.submitDeclineExamEnglish : this.submitDeclineExamArabic;
            } else {
                return this.direction === 'LTR' ? this.submitDeclineExerciseEnglish : this.submitDeclineExerciseArabic;
            }
        },
        correctAnswerText() {
            if (this.direction === 'LTR')
                return this.correctAnswerTextEnglish;
            else
                return this.correctAnswerTextArabic;
        },
        wrongAnswerText() {
            if (this.direction === 'LTR')
                return this.wrongAnswerTextEnglish;
            else
                return this.wrongAnswerTextArabic;
        },
        explainAnswerText() {
            if (this.direction === 'LTR')
                return this.explainAnswerTextEnglish;
            else
                return this.explainAnswerTextArabic;
        },
        yourCorrectAnswerText() {
            if (this.direction === 'LTR')
                return this.yourCorrectAnswerTextEnglish;
            else
                return this.yourCorrectAnswerTextArabic;
        },
        checkAnswerText() {
            if (this.direction === 'LTR')
                return this.checkAnswerTextEnglish;
            else
                return this.checkAnswerTextArabic;
        },
        nextQuestionText() {
            if (this.direction === 'LTR')
                return this.nextQuestionTextEnglish;
            else
                return this.nextQuestionTextArabic;
        },
        submitExerciseText() {
            if (this.direction === 'LTR')
                return this.submitExerciseEnglish;
            else
                return this.submitExerciseTextArabic;
        },

        resultGradeText() {
            if (this.direction === 'LTR')
                return this.resultGradeTextEnglish;
            else
                return this.resultGradeTextArabic;
        },

        resultTimeText() {
            if (this.direction === 'LTR')
                return this.resultTimeTextEnglish;
            else
                return this.resultTimeTextArabic;
        },

        examSubmitButtonText() {
            if (this.direction === 'LTR')
                return this.examButtonEnglishText;
            else
                return this.examButtonArabicText;
        },

    },
    methods: {
        handleCloseExamOrExercise(){
            routes.navigateToExercise(this.lessonSlug, this.exerciseSlug);
        }
    }

};