import { createRouter, createWebHistory } from "vue-router";
import store from "@/store";

// Lazy load components
import Home from "@/views/pages/Home.vue";
import Register from "@/views/pages/SignUp.vue";
import RegisterOTP from "@/components/SignUp/SignUpPhoneVerification.vue";
import RegisterAccountInfo from "@/components/SignUp/SignUpAccountInfo.vue";
import RegisterEducationInfo from "@/components/SignUp/SignUpEducationInfo.vue";
import RegisterDone from "@/components/SignUp/SignUpDone.vue";
import Login from "@/views/pages/Login.vue";
import ForgotPassword from "@/views/pages/ForgotPassword.vue";
import ForgotPasswordNewPassword from "@/components/ForgotPassword/ForgotPasswordNewPassword.vue";
import ChangeNewPassword from "@/components/ChangeNewPassword.vue";
import ChangePhoneNumber from "@/views/pages/ChangePhoneNumber.vue";
import ChangePhoneVerification from "@/components/ChangePhoneNumber/ChangePhoneVerification.vue";
import MainProfile from "@/views/pages/MyProfile/MainProfile.vue";
import EditProfile from "@/views/pages/MyProfile/EditProfile.vue";
import Subscription from "@/views/pages/Subscription/Subscription.vue";
import SubscriptionPerSubject from "@/views/pages/Subscription/SubscriptionPerSubject.vue";
import AddToCart from "@/views/pages/Subscription/addToCart.vue";
import FawryCheckout from "@/components/Subscriptions/FawryCheckout.vue";
import Page404 from "@/views/pages/Page404.vue";
import StudyContent from "../views/pages/StudyContent/Index.vue";
import Course from "../views/pages/Course/Index.vue";
import Lesson from "../views/pages/Lesson/Index.vue";
import Video from "../views/pages/Video/Index.vue";
import Exercise from "@/views/pages/Exercise/Info.vue";
import ExerciseStart from "@/views/pages/Exercise/Start.vue";
import PaperSummary from "@/views/pages/PaperSummary/Index.vue";
import Result from "@/views/pages/Exercise/Result.vue";
import TermsOfUse from "@/views/pages/TermsOfUse.vue";
import AboutUs from "@/views/pages/AboutUs.vue";
import AskTeacher from "@/views/pages/AskTeacher.vue";
import RefundPolicy from "@/views/pages/RefundPolicy.vue";
import PrivacyPolicy from "@/views/pages/PrivacyPolicy.vue";


import routeNames from "@/router/routes.js";
import { otpCookie } from "@/helpers/OTPCookie";
import cookieEncryption from "@/helpers/cookieEncryption";
import VueCookies from "vue-cookies";

const isValidOTP = () => {
  const { studentPhone = "" } =
    cookieEncryption.getCookie(VueCookies, "elkhetaRegistrationData") || {};
  const { isValidSentOTP = false } =
    otpCookie.getOTP(VueCookies, studentPhone) || {};
  return isValidSentOTP;
};
const isEducationTypeAndEducationStageSelected = () => {
  const registrationData = cookieEncryption.getCookie(
    VueCookies,
    "elkhetaRegistrationData"
  );

  if (!registrationData) {
    return false;
  }

  const { studentEducationType, studentEducationStage } = registrationData;

  return Boolean(studentEducationType && studentEducationStage);
};

const routes = [
  {
    path: routeNames.HOME.path,
    name: routeNames.HOME.name,
    component: Home,
    meta: {
      hideSideBar: true,
      requiresAuth: false,
      showFooter: true,
      forceTopNavBar: true,
      fixedTopNavBar: true,
    },
  },
  {
    path: routeNames.REGISTER.path,
    meta: { requiresGuest: true, hideSideBar: true },
    children: [
      {
        path: "",
        name: routeNames.REGISTER.name,
        component: Register,
        meta: { requiresGuest: true, hideSideBar: true },
      },
      {
        path: routeNames.REGISTER_OTP.path,
        name: routeNames.REGISTER_OTP.name,
        component: RegisterOTP,
        meta: { requiresGuest: true, hideSideBar: true },

        beforeEnter: (to, from, next) => {
          if (isValidOTP()) {
            next(
              routeNames.REGISTER.path +
                "/" +
                routeNames.REGISTER_ACCOUNT_INFO.path
            );
          } else {
            next();
          }
        },
      },
      {
        path: routeNames.REGISTER_ACCOUNT_INFO.path,
        name: routeNames.REGISTER_ACCOUNT_INFO.name,
        component: RegisterAccountInfo,
        meta: { requiresGuest: true, hideSideBar: true },

        beforeEnter: (to, from, next) => {
          if (!isValidOTP()) {
            next({
              path: routeNames.REGISTER.path,
              query: { requiredOTPValidation: true },
            });
          } else {
            next();
          }
        },
      },
      {
        path: routeNames.REGISTER_EDUCATION_INFO.path,
        name: routeNames.REGISTER_EDUCATION_INFO.name,
        component: RegisterEducationInfo,
        meta: { requiresGuest: true, hideSideBar: true },

        beforeEnter: (to, from, next) => {
          if (!isValidOTP()) {
            next({
              path: routeNames.REGISTER.path,
              query: { requiredOTPValidation: true },
            });
          } else if (!isEducationTypeAndEducationStageSelected()) {
            next({
              path:
                routeNames.REGISTER.path +
                "/" +
                routeNames.REGISTER_ACCOUNT_INFO.path,
              query: { requiredEducationTypeAndEducationStage: true },
            });
          } else {
            next();
          }
        },
      },
      {
        path: routeNames.REGISTER_DONE.path,
        name: routeNames.REGISTER_DONE.name,
        meta: { requiresAuth: true, hideSideBar: true, forceTopNavBar: true },
        component: RegisterDone,
      },
    ],
  },
  {
    path: routeNames.SUBSCRIPTIONS.path,
    name: routeNames.SUBSCRIPTIONS.name,
    meta: { requiresAuth: true },
    component: Subscription,
  },
  {
    path: routeNames.SUBSCRIPTIONS_PER_SUBJECT.path,
    name: routeNames.SUBSCRIPTIONS_PER_SUBJECT.name,
    meta: { requiresAuth: true },
    component: SubscriptionPerSubject,
  },
  {
    path: routeNames.CART.path,
    name: routeNames.CART.name,
    meta: { requiresAuth: true },
    component: AddToCart,
  },
  {
    path: routeNames.FAWRY_CHECKOUT.path,
    name: routeNames.FAWRY_CHECKOUT.name,
    meta: { requiresAuth: true },
    component: FawryCheckout,
  },
  {
    path: routeNames.LOGIN.path,
    name: routeNames.LOGIN.name,
    meta: { requiresGuest: true, hideSideBar: true },
    component: Login,
  },
  {
    path: routeNames.LOGOUT.path,
    name: routeNames.LOGOUT.name,
    beforeEnter: (to, from, next) => {
      store.dispatch("Auth/logoutUser");
      next({ path: routeNames.LOGIN.path });
    },
    meta: { requiresAuth: true },
    component: Login,
  },
  {
    path: routeNames.MY_PROFILE.path,
    name: routeNames.MY_PROFILE.name,
    meta: { requiresAuth: true },
    component: MainProfile,
  },
  {
    path: routeNames.EDIT_MY_PROFILE.path,
    name: routeNames.EDIT_MY_PROFILE.name,
    meta: { requiresAuth: true },
    component: EditProfile,
  },
  {
    path: routeNames.FORGOT_PASSWORD.path,
    name: routeNames.FORGOT_PASSWORD.name,
    meta: { requiresGuest: true, hideSideBar: true },
    component: ForgotPassword,
  },
  {
    path: routeNames.RESET_PASSWORD.path,
    name: routeNames.RESET_PASSWORD.name,
    meta: { requiresGuest: true, hideSideBar: true },
    component: ForgotPasswordNewPassword,
  },
  {
    path: routeNames.EDIT_MY_PASSWORD.path,
    name: routeNames.EDIT_MY_PASSWORD.name,
    meta: { requiresAuth: true },
    component: ChangeNewPassword,
  },
  {
    path: routeNames.EDIT_MY_PHONE_NUMBER.path,
    name: routeNames.EDIT_MY_PHONE_NUMBER.name,
    meta: { requiresAuth: true },
    component: ChangePhoneNumber,
  },
  {
    path: routeNames.EDIT_MY_PHONE_NUMBER_VERIFICATION.path,
    name: routeNames.EDIT_MY_PHONE_NUMBER_VERIFICATION.name,
    meta: { requiresAuth: true },
    component: ChangePhoneVerification,
  },
  {
    path: routeNames.STUDY_CONTENT.path,
    name: routeNames.STUDY_CONTENT.name,
    meta: { requiresAuth: true },
    component: StudyContent,
  },
  {
    path: routeNames.COURSE.path,
    name: routeNames.COURSE.name,
    meta: { requiresAuth: true },
    component: Course,
  },
  {
    path: routeNames.LESSON.path,
    name: routeNames.LESSON.name,
    meta: { requiresAuth: true },
    component: Lesson,
  },
  {
    path: routeNames.LESSON_PAPER_SUMMARY.path,
    name: routeNames.LESSON_PAPER_SUMMARY.name,
    component: PaperSummary,
    meta: { hideSideBar: true, requiresAuth: true, forceHideTopNavBar: true },
  },
  {
    path: routeNames.LESSON_VIDEO.path,
    name: routeNames.LESSON_VIDEO.name,
    component: Video,
    meta: { hideSideBar: true, requiresAuth: true, forceHideTopNavBar: true },
  },
  {
    path: routeNames.LESSON_EXERCISE_INFO.path,
    name: routeNames.LESSON_EXERCISE_INFO.name,
    component: Exercise,
    meta: { hideSideBar: true, requiresAuth: true, forceHideTopNavBar: true },
  },
  {
    path: routeNames.LESSON_EXERCISE_START.path,
    name: routeNames.LESSON_EXERCISE_START.name,
    component: ExerciseStart,
    meta: { hideSideBar: true, requiresAuth: true, forceHideTopNavBar: true },
  },
  {
    path: routeNames.LESSON_EXERCISE_RESULT.path,
    name: routeNames.LESSON_EXERCISE_RESULT.name,
    component: Result,
    meta: { hideSideBar: true, requiresAuth: true },
  },
  {
    path: routeNames.TERMS_OF_USE.path,
    name: routeNames.TERMS_OF_USE.name,
    component: TermsOfUse,
    meta: { hideSideBar: true, showFooter: true, forceTopNavBar: true },
  },
  {
    path: routeNames.REFUND_POLICY.path,
    name: routeNames.REFUND_POLICY.name,
    component: RefundPolicy,
    meta: { hideSideBar: true, showFooter: true, forceTopNavBar: true },
  },
  {
    path: routeNames.PRIVACY_POLICY.path,
    name: routeNames.PRIVACY_POLICY.name,
    component: PrivacyPolicy,
    meta: { hideSideBar: true, showFooter: true, forceTopNavBar: true },
  },

  {
    path: routeNames.ABOUT_US.path,
    name: routeNames.ABOUT_US.name,
    component: AboutUs,
    meta: { hideSideBar: true, showFooter: true, forceTopNavBar: true },
  },
  {
    path: routeNames.ASK_TEACHERS.path,
    name: routeNames.ASK_TEACHERS.name,
    component: AskTeacher,
    meta: { requiresAuth: true },
  },
  {
    path: "/:pathMatch(.*)*",
    name: "NotFound",
    component: Page404,
    meta: { requiresAuth: false, hideSideBar: true },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  const isLoggedIn = store.getters["Auth/isAuth"];
  const requiresAuth = to.matched.some((rec) => rec.meta.requiresAuth);
  const requiresGuest = to.matched.some((rec) => rec.meta.requiresGuest);

  if (requiresAuth) {
    if (isLoggedIn) {
      next();
    } else {
      store
        .dispatch("Auth/logoutUser")
        .then(() =>
          next({ path: routeNames.LOGIN.path, query: { loginRequired: true } })
        );
    }
  } else if (requiresGuest) {
    if (isLoggedIn) {
      next({ path: routeNames.STUDY_CONTENT.path });
    } else {
      next();
    }
  } else {
    if (isLoggedIn && to.path === routeNames.HOME.path) {
      next({ path: routeNames.STUDY_CONTENT.path });
    } else {
      next();
    }
  }
});

export default router;
