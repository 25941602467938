import Vuex from "vuex";
import Auth from '@/store/Auth/index'
import Order from '@/store/Order/index'


const requireComponents = require.context(
  "./",
  true,
  /index.js$/
);

let modules = { Auth , Order }

requireComponents.keys().forEach((fileName) => {
  if (fileName === './index.js') return;
  const componentConfig = requireComponents(fileName);
  const parts = fileName.split("/");
  const componentName = parts[parts.length - 2].split('-')
    .map((part, index) => index === 0 ? part : part[0].toUpperCase() + part.slice(1))
    .join('');
  modules[componentName] = componentConfig.default || componentConfig;
});

export default new Vuex.Store({
  modules,

});
