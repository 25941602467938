import {ApolloClient, InMemoryCache, HttpLink} from '@apollo/client/core';
import {provideApolloClient} from '@vue/apollo-composable';
import {setContext} from '@apollo/client/link/context';
import {logErrorMessages} from '@vue/apollo-util'
import {onError} from '@apollo/client/link/error'
import {loginCookie} from '@/helpers/loginCookie';
import VueCookies from 'vue-cookies';
import store from "@/store";
import router from '@/router';

const httpLink = new HttpLink({
    uri: 'https://elkheta.org/graphql',
    credentials: 'include',
});

const checkMessageCode = (responseObject, messageCode) => {
    return (
        responseObject.response &&
        responseObject.response.errors &&
        responseObject.response.errors.length > 0 &&
        responseObject.response.errors[0].message_code === messageCode
    );
};

const checkLoginRequired = (responseObject) => {
    return checkMessageCode(responseObject, "LOGIN_REQUIRED");
};

const checkAlreadyLoggedIn = (responseObject) => {
    return checkMessageCode(responseObject, "ALREADY_LOGGED_IN");
};

const checkCartEmpty = (responseObject) => {
    return checkMessageCode(responseObject, "CART_EMPTY");
};

const authLink = setContext((_, {headers}) => {

    const token = loginCookie.getAuthToken(VueCookies);

    return {
        headers: {
            ...headers,
            authorization: token ? `Bearer ${token}` : "",
        }
    }
});


const errorLink = onError(error => {
    const isLoginRequired = checkLoginRequired(error);
    const isAlreadyLoggedIn = checkAlreadyLoggedIn(error);
    const isCartEmpty = checkCartEmpty(error);

    if (isLoginRequired) {
        store.dispatch('Auth/logoutUser', VueCookies);

        router.push({
            name: 'Login',
            query: {loginRequired: true}
        });
    }

    if (isAlreadyLoggedIn) {
        router.push({
            name: 'studyContent',
        });
    }

    if (isCartEmpty) {
        router.push({
            name: 'Subscription',
            query: {
                cartEmpty: true
            }
        });
    }

    if (process.env.NODE_ENV !== 'production') {
        logErrorMessages(error)
    }
})


const cache = new InMemoryCache();

const apolloClient = new ApolloClient({
    link: errorLink.concat(authLink.concat(httpLink)),
    cache,
});

provideApolloClient(apolloClient);

export default apolloClient;
