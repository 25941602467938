<template>
  <FormField
      inputType="text"
      name="phone"
      :rules="rules"
      :modelValue="modelValue"
      :fieldClass="styleClass"
      :errorClass="errorClass"
      :eyeClass="eyeClass"
      @update:modelValue="$emit('update:modelValue', $event)"
      id="phoneNumber"
      :placeholder="placeholder"
      :containerFieldClass="containerFieldClass"

  >
    <template v-slot:icon>
      <slot name="icon"></slot>
    </template>
  </FormField>
</template>

<script>
import FormField from "@/components/FormComponents/FormField.vue";

export default {
  name: 'PhoneNumberInput',
  components: { FormField },
  props: {
    modelValue: {
      type: [String, Number],
      required: true
    },
    placeholder: {
      type: String,
      default: 'ادخل رقم تليفون صحيح'
    },
    rules: {
      type: [String, Object, Function],
      default: ''
    },
    styleClass: {
      type: String,
      default: 'p-2 pr-[17px] md:pr-[29px] inputBorderStyle w-[284px] md:w-[375px] h-[54px] rounded-[10px] text-right'
    },
    errorClass: {
      type: String,
      default: 'text-[#C50000] w-[284px] md:w-[375px] m-auto text-right pr-2 mt-1'
    },
    eyeClass: {
      type: String,
      default: 'absolute w-[20px] h-[20px] left-[5%] md:left-[16%] cursor-pointer ml-2'
    },
    containerFieldClass: {
      type: String,
      default: 'relative w-[284px] md:w-[500px] h-[54px] m-auto rounded-[10px] flex justify-center items-center'
    },
  },
};
</script>