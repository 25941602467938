<template>
  <div
    :dir="dir"
    class="py-4 flex flex-col item-start dynamic-align   text-17px max-w-3xl lg:w-4/12 2xl:w-3/12 mx-auto"
  >
    <h4 class="text-blue-custom mb-4 font-bold-500 lg:font-bold-400 dynamic-align">
    {{ text }}
    </h4>
    <div class="flex flex-col justify-start gap-2 items-start">
      <div
        v-for="previousAnswer in previousAnswers"
        :key="previousAnswer.id"
        class="grid grid-cols-4 gap-5 justify-center items-center text-dark-blue-custom"
      >
        <span class="flex gap-2 align-center col-span-2">
          <svgicon :data="Chart" width="17" height="17" original />
          <span> {{ previousAnswer?.score }}% </span>
        </span>
        <span class="col-span-1">{{ previousAnswer?.formatted_time }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import Chart from "@/assets/svg/blue/Chart.svg";

export default {
  components: {},
  props: {
    previousAnswers: {
      type: [Object, Array],
      required: true,
    },
    dir: {
      type: String,
      required: true,
    },
    text: {
      type: String,
      required: true,
    }
  },
  data() {
    return {
      Chart,
    };
  },
};
</script>
