<template>
  <div class="grid grid-cols-12 mt-[4%] items-center">
    <div class="md:block md:col-span-5">
      <img :src="boy" width="580" height="538" />
    </div>
    <div class="flex flex-col items-center col-span-12 md:col-span-6">
      <div class="mx-auto flex justify-center items-center">
        <div class="flex items-center font-bold-500 gap-1">
          <h6 class="mr-10 md:mr-40 text-21px lg:text-3xl">
            محتوى <span class="text-blue-custom font-bold-700 lg:text-35px"> كامل </span> بين ايديك
          </h6>
          <img :src="book" width="118" height="132" />
        </div>
      </div>

      <div class="text-blue-custom text-base lg:text-25px font-bold-500 lg:font-bold-700 flex flex-col dynamic-align">
        <div class="grid grid-cols-8 items-center">
          <img class="col-span-1 w-16" :src="video" />
          <span class="col-span-7 ml-4"> فيديوهات شرح شاملة كل جزء في المنهج </span>
        </div>
        <div class="grid grid-cols-8  items-center mt-4 md:mt-4">
          <img class="col-span-1 w-16" :src="paper" />
          <span class="col-span-7 ml-4"> تمارين و واجبات تفاعلية بعد كل حصة </span>
        </div>
        <div class="grid grid-cols-8 items-center mt-4 md:mt-4">
          <img class="col-span-1 w-16" :src="idea" />
          <span class="col-span-7 ml-4"> مراجعات شاملة و مكثفة قبل الامتحانات </span>
        </div>
        <div class="grid grid-cols-8 items-center mt-4 md:mt-4">
          <svgicon class="col-span-1 w-16" :data="books" original :width="isMobile ? 62 : 60" :height="isMobile ? 50 : 61" :fill="true" />
          <span class="col-span-7 ml-4"> ملخصات جاهزة للطباعة لكل حصة </span>
        </div>
      </div>

    </div>
  </div>

  <div class="grid grid-cols-12 mt-[150px] mb-[70px]">
    <div class="flex flex-col items-center col-span-12 md:col-span-8 3xl:mt-[10%]">
      <div class="mx-auto flex justify-center items-center">
        <div class="flex items-center font-bold-500 text-xl lg:text-3xl">
          <h6 class="mr-10 md:mx-2">
            <span class="text-blue-custom text-21px lg:text-35px font-bold-700"> مش </span>
            هتكون
            <span class="text-blue-custom text-21px lg:text-35px font-bold-700">لوحدك</span>
            أبدا
          </h6>
          <img :src="heart" width="101" height="57" />
        </div>
      </div>

      <div class="text-blue-custom text-base lg:text-25px font-bold-500 lg:font-bold-700 flex flex-col dynamic-align">
        <div class="grid grid-cols-8 items-center">
          <img class="col-span-1 w-16" :src="DoubleHeart" :width="isMobile ? 71 : 100"
            :height="isMobile ? 54 : 60" />
          <span class="col-span-7"> مشرف خاص لمتابعة مستواك الدراسي</span>
        </div>
        <div class="grid grid-cols-8 items-center mt-4 md:mt-4">
          <svgicon :data="support" class="col-span-1 w-16" :width="isMobile ? 62 : 60" :height="isMobile ? 50 : 61" original :fill="false" />
          <span class="col-span-7 ml-4"> تواصل مباشر مع المدرسين للإجابة عن أسئلتك</span>
        </div>
        <div class="grid grid-cols-8 items-center mt-4 md:mt-4">
          <img class="col-span-1 w-16" :src="Calendar" :width="isMobile ? 71 : 100"
            :height="isMobile ? 50 : 58" />
          <span class="col-span-7 ml-4"> جداول مذاكرة يومية منظمة لكل المواد</span>
        </div>
        <div class="grid grid-cols-8 items-center mt-4 md:mt-4">
          <img class="col-span-1 w-16" :src="report" :width="isMobile ? 49 : 100" :height="isMobile ? 50 : 58" />
          <span class="col-span-7 ml-4"> تقارير شهرية لولي الأمر لمتابعة مستواك</span>
        </div>
      </div>
    </div>
    <div class="md:block col-span-4 relative">
      <img class="absolute md:-bottom-[80px] lg:-bottom-[150px] 3xl:-bottom-[240px]" :src="phone" width="634"
        height="640" />
    </div>
  </div>
</template>

<script>
import books from "@/assets/svg/landing/books.svg";
import support from "@/assets/svg/landing/support.svg";
import windowSize from '@/mixins/windowSize';

export default {
  mixins: [windowSize],

  data() {
      return {
        books:books,
        support: support
      }
  },
  props: {
    boy: String,
    book: String,
    video: String,
    paper: String,
    idea: String,
    heart: String,
    DoubleHeart: String,
    Calendar: String,
    report: String,
    phone: String
  },
}
</script>
