<template>
  <div class="w-full relative top-0 left-auto flex flex-col items-center min-h-screen">
    <LoadingSpinner :is-loading="isLoading"/>

    <BackButton :customGoBack="handleGoBackFromLogin" class="absolute top-[76px] left-[85%] md:absolute md:top-[13.15%] md:left-[66.3%] z-[1000]"/>
    <div class=" absolute top-[0%] md:top-[11%] flex flex-col items-center w-full z-[999]">
      <LoginRequiredMessage/>
      <LoginStartAndLog/>
      <GirafeImage/>
      <div class="w-full flex flex-col content-center justify-center flex-wrap" dir="ltr">
  
        <Form class="w-full" @submit="onSubmit" action="">
  
          <div class="w-full">
            <div class=" flex flex-column justify-center">
  
              <PhoneNumberInput
                  v-model="phoneNumber"
                  placeholder="رقم تليفونك المسجل"
                  :rules="isValidPhoneForLogin"
              />
  
            </div>
          </div>
  
  
          <div class=" w-full">
            <div class=" flex flex-column justify-center">  
                <PasswordInput
                    v-model="password"
                    placeholder="الباسوورد الخاص بحسابك"
                />
            </div>
          </div>
  
          <div v-if="errors.length > 0" class="text-[#C50000] w-[284px] md:w-[375px] m-auto text-right pr-2 mt-1">
            <span v-for="(error, index) in errors" :key="index">
              {{ error }}
            </span>
          </div>

          <BlueButton type="submit" class="mt-[31px] mb-[15px]">تسجيل الدخول</BlueButton>
  
        </form>
        
        <router-link class="mb-0"  :to="{ name: routes.FORGOT_PASSWORD.name }">
            <span class="text"><a class="text-gray-custom text-[17px] underline cursor-pointer">نسيت الباسوورد</a></span>
        </router-link>
  
        <router-link class="mt-[74px]" :to="{ name: routes.REGISTER.name }">
          <span class="text ">ماعندكش حساب ؟ <a href="" class="text-blue-custom text-[17px] underline fw-bold terms">إنشاء حساب جديد</a></span>
        </router-link>
  
      </div> 

    </div>
  </div>
  
</template>

<script>
import BackButton from '@/components/FormComponents/BackButton.vue';
import LoginStartAndLog from "@/components/FormComponents/LoginStartAndLog.vue";
import LoginRequiredMessage from "@/components/FormComponents/LoginRequiredMessage.vue";
import { Form } from 'vee-validate';
import { login } from '@/graphql/mutations/login/handlingCalls';
import { mapActions } from 'vuex';
import GirafeImage from "@/components/FormComponents/GirafeImage.vue";
import PhoneNumberInput from "@/components/FormComponents/PhoneNumberInput.vue";
import { isValidPhoneForLogin} from '@/validations/isValidPhone';
import PasswordInput from "@/components/FormComponents/PasswordInput.vue";
import BlueButton from "@/components/FormComponents/BlueButton.vue";
import routes from '@/router/routes.js';

export default {

  name: 'LoginPage',
  computed: {
    routes() {
      return routes
    }
  },
  components:{
    BlueButton,
    PasswordInput,
    PhoneNumberInput,
    GirafeImage,
    Form,
    LoginStartAndLog,
    LoginRequiredMessage,
    BackButton
  },
  data() {
        return {
          phoneNumber: '',
          password: '',
          errors: [],
          isLoading: false,
        }
      },
    methods:{
      ...mapActions({
        loginUser: 'Auth/loginUser'
      }),
      handleGoBackFromLogin(){
        routes.navigateTo(routes.HOME.key)
      },
      isValidPhoneForLogin,
      async onSubmit(){
          
        this.errors = [];
        let userData = {
          phone_number: this.phoneNumber,
          password: this.password,
        }


        let loginResponse = await this.$withLoading(() => login(userData));

        if (loginResponse.success === false){
          this.errors = loginResponse.displayValidationMessages;
        }
        else {
          const token = loginResponse.data.token;

          this.loginUser(token)

          routes.navigateTo(routes.STUDY_CONTENT.key);
        }
          
      },
  },
}

</script>

