<template>
  <div class="flex flex-column min-h-screen items-center">
    <div class="w-full max-w-3xl px-4 mt-6 ">
      <div :dir="direction">
        <ExerciseHeader
          class="fixed-content"
          :textColor="isMobile ? 'black' : 'blue-custom'" 
          :exerciseTitle="name"
          @x-icon-clicked="handleXIconClicked"
        >
          <div class="flex align-center gap-3">
            <HeaderCounter
              :all="totalCount"
              :counter="currentIndex"
              v-if="!isMobile"
            />
            <CheckMarkIcon :isFinished="isCompleted" :width="isMobile ? 20 : 28" :height="isMobile ? 20 : 28" />
          </div>
        </ExerciseHeader>


      </div>
    </div>

    <div class="flex-1 mx-auto mt-[3rem] md:mt-[8rem] 3xl:mt-[2rem] w-11/12 px-6">
      <NumberOfTriesError />
      <ContentNotCompletedError />
      <ExerciseInfo
        :dir="direction"
        @start="startExercise"
        :buttonText="exerciseOrExamStartText"
        :exercise="exercise"
      />
      <PreviousAnswers
        class="mt-10"
        :text="PreviousAnswersText"
        :dir="direction"
        v-if="$isNotEmpty(previousAnswers)"
        :previousAnswers="previousAnswers"
      />
    </div>

    <FooterBlur class="p-4" v-if="isMobile">
      <div class="max-w-3xl mx-auto">
        <div class="grid grid-cols-6 gap-4 text-center items-center">
          <span
            class="py-2 rounded-full bg-light-gray-custom text-gray-font cursor-pointer"
            @click="handleBackClicked"
          >
            <DirectionalForwardIcon color="black" v-if="isValidPrevious" />
            <i v-else class="fa-solid fa-xmark text-black"></i>
          </span>
          <span
            @click="startExercise"
            class="col-span-4 py-2 rounded-full bg-blue-custom text-white-custom font-bold-600"
          >
            {{ exerciseOrExamStartText }}
          </span>
          <span
            class="py-2 rounded-full bg-light-gray-custom text-gray-font cursor-pointer"
            @click="handleNextClicked"
          >
            <DirectionalBackIcon
              color="black"
              v-if="isValidNext"
              :dir="direction"
              :is-back="false"
            />
            <i v-else class="fa-solid fa-xmark text-black"></i>
          </span>
        </div>
      </div>
    </FooterBlur>

    <FooterBlur class="p-4" v-else>
      <NavigationButtons
        :previousText="previousText"
        :nextText="nextText"
        :isValidNext="isValidNext"
        :isValidPrevious="isValidPrevious"
        @back-clicked="handleBackClicked"
        @next-clicked="handleNextClicked"
      />
    </FooterBlur>
  </div>
</template>

<script>
import ExerciseInfo from "@/components/Exercise/ExerciseInfo.vue";
import PreviousAnswers from "@/components/Exercise/PreviousAnswers.vue";
import FooterBlur from "@/components/General/FooterSection.vue";
import ExerciseHeader from "@/components/General/Header.vue";
import CheckMarkIcon from "@/components/General/CheckMarkIcon.vue";
import DirectionalBackIcon from "@/components/Icons/DirectionalArrowIcon.vue";
import DirectionalForwardIcon from "@/components/Icons/DirectionalArrowIconReverse.vue";
import HeaderCounter from "@/components/General/HeaderCounter.vue";
import routeParamsMixin from "@/mixins/routeParams";
import contentDataMixin from "@/mixins/contentData";
import windowSizeMixin from "@/mixins/windowSize";
import routes from "@/router/routes";
import NumberOfTriesError from "@/components/General/NumberOfTriesError.vue";
import ContentNotCompletedError from "@/components/General/ContentNotCompletedError.vue";
import NavigationButtons from "@/components/General/NavigationButtons.vue";

export default {
  mixins: [routeParamsMixin, contentDataMixin, windowSizeMixin],

  name: "ExerciseInfoContent",
  components: {
    ContentNotCompletedError,
    NumberOfTriesError,
    ExerciseInfo,
    PreviousAnswers,
    FooterBlur,
    CheckMarkIcon,
    ExerciseHeader,
    DirectionalBackIcon,
    DirectionalForwardIcon,
    HeaderCounter,
    NavigationButtons,
  },
  props: {
    contentDataProp: {
      type: String,
      default: "exercise",
    },
    contentErrorDataProp: {
      type: String,
      default: "exerciseError",
    },
    exercise: {
      type: Object,
      required: true,
    },
    exerciseError: {
      type: [Object, Array, String, null],
      required: true,
    },
  },
  methods: {
    startExercise() {
      Object.assign(this.$data, this.getRouteParamsData(this.$route.params));
      routes.navigateToExerciseStart(this.lessonSlug, this.exerciseSlug);
    },
  },
  computed: {
    name() {
      return this.exercise?.name || "";
    },
    previousAnswers() {
      return this.exercise?.completed_exercise_sessions || [];
    },
  },
};
</script>
