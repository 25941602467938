<template>
    <div class="video-carousel-container relative pb-5">
        <button v-if="showNext" @click="next"
            class="left-arrow absolute left-0 top-1/2 transform -translate-y-1/2 z-99999">
            <svg width="24" height="24" fill="currentColor" viewBox="0 0 24 24">
                <path d="M15.41 7.41L14 6l-6 6 6 6 1.41-1.41L10.83 12z"></path>
            </svg>
        </button>
        <div class="video-carousel overflow-x-auto whitespace-nowrap cursor-grab" ref="carousel" @mousedown="startDrag"
            @mousemove="drag" @mouseup="stopDrag" @mouseleave="stopDrag">
            <div v-for="(video, index) in videos" :key="index" class="inline-block mr-6 text-center">
                <div
                    class="video-container h-72 lg:h-video-height overflow-hidden flex items-center justify-center mx-auto">
                    <iframe :src="video.src" loading="lazy" :style="{ width: videoSize, height: '100%' }"
                        allow="accelerometer; gyroscope; autoplay; encrypted-media; picture-in-picture;"></iframe>
                </div>
                <div class="mt-4 text-center font-bold cursor-grab">
                    <h4 class="text-xs lg:text-lg">{{ video.title }}</h4>
                    <p class="text-sm lg:text-xl">{{ video.subtitle }}</p>
                </div>
            </div>
        </div>
        <button v-if="showPrev" @click="prev"
            class="right-arrow absolute right-0 top-1/2 transform -translate-y-1/2 z-10">
            <svg width="24" height="24" fill="currentColor" viewBox="0 0 24 24">
                <path d="M8.59 16.59L10 18l6-6-6-6-1.41 1.41L13.17 12z"></path>
            </svg>
        </button>
    </div>
</template>

<script>

import windowSize from "@/mixins/windowSize";

export default {
  mixins: [windowSize],
  name: "VideoCarousel",
    data() {
        return {
            videos: [
                { src: "https://iframe.mediadelivery.net/embed/278734/7cab7e38-9c0b-4e1a-8c9b-8ff7c9a291c4?autoplay=false&loop=false&muted=false&preload=true&responsive=true", title: "عمر وائل", subtitle: "القاهرة، الثانى الثانوي" },
                { src: "https://iframe.mediadelivery.net/embed/278734/aefc473d-d203-4ff6-8138-b2ca8468793a?autoplay=false&loop=false&muted=false&preload=true&responsive=true", title: "نور مجدى", subtitle: "الإسكندرية، الثالث الإعدادي" },
                { src: "https://iframe.mediadelivery.net/embed/278734/eb4e6e79-d0c0-47c8-aaa3-a5bc46ed7697?autoplay=false&loop=false&muted=false&preload=true&responsive=true", title: "ملك وائل", subtitle: "القاهرة، الثانى الثانوي" },
                { src: "https://iframe.mediadelivery.net/embed/278734/10398ed0-58bd-40e5-8991-e32614856984?autoplay=false&loop=false&muted=false&preload=true&responsive=true", title: "ملك مصطفى", subtitle: "القاهرة، الثالث الإعدادي" },
                { src: "https://iframe.mediadelivery.net/embed/278734/6268d8c9-6eaf-43f8-86cd-01d65f28e29e?autoplay=false&loop=false&muted=false&preload=true&responsive=true", title: "محمد ايهاب", subtitle: "كفر الشيخ، الأول الثانوي" },
                { src: "https://iframe.mediadelivery.net/embed/278734/01aa09fc-07c0-415a-a3f8-4aa25db9ed3d?autoplay=false&loop=false&muted=false&preload=true&responsive=true", title: "حازم إسماعيل", subtitle: "القاهرة، الثانى الثانوي" },
                { src: "https://iframe.mediadelivery.net/embed/278734/8327a716-ef02-41fb-ab12-55402894c84c?autoplay=false&loop=false&muted=false&preload=true&responsive=true", title: "عبد الرحمن بلال", subtitle: "البحيرة ، الأول الثانوي" },
            ],
            isDragging: false,
            startX: 0,
            scrollLeft: 0,
            scrollSpeed: 2,
            showNext: false,
            showPrev: false,
            videoWidth: 400,
            videoTracker: 0,
        };
    },
    computed: {
        videoSize() {
            return `${this.videoWidth}px`;
        },
        visibleWidth() {
            return this.$refs.carousel.clientWidth;
        }
    },
    methods: {
        startDrag(e) {
            this.isDragging = true;
            this.startX = e.pageX - this.$refs.carousel.offsetLeft;
            this.scrollLeft = this.$refs.carousel.scrollLeft;
            this.$refs.carousel.style.cursor = "grabbing";
        },
        drag(e) {
            if (!this.isDragging) return;
            const x = e.pageX - this.$refs.carousel.offsetLeft;
            const walk = (x - this.startX) * this.scrollSpeed;
            this.$refs.carousel.scrollLeft = this.scrollLeft - walk;
        },
        stopDrag() {
            this.isDragging = false;
            this.$refs.carousel.style.cursor = "grab";
        },
        next() {
            this.$refs.carousel.scrollLeft -= this.videoWidth;
            this.videoTracker = this.videoTracker + this.videoWidth;
            this.checkArrowVisibility();
        },
        prev() {
            this.$refs.carousel.scrollLeft += this.videoWidth;
            this.videoTracker = this.videoTracker - this.videoWidth;
            this.checkArrowVisibility();
        },
        numberOfVideosDisplayed() {
            return Math.floor(this.visibleWidth / this.videoWidth);
        },
        checkArrowVisibility() {

          if (this.isMobile){
            this.showPrev = false
            this.showNext = false;
          }else{
            const { scrollLeft, scrollWidth, clientWidth } = this.$refs.carousel;
            this.showPrev = scrollLeft < 0;
            this.showNext = Math.abs(scrollLeft) + clientWidth < scrollWidth;
          }

        }
    },
    mounted() {
        this.$refs.carousel.style.cursor = "grab";
        this.$refs.carousel.addEventListener('scroll', this.checkArrowVisibility);
        this.$refs.carousel.addEventListener('resize', this.checkArrowVisibility);
        this.checkArrowVisibility();
    },
    beforeUnmount() {
        this.$refs.carousel.removeEventListener('scroll', this.checkArrowVisibility);
        this.$refs.carousel.removeEventListener('resize', this.checkArrowVisibility);

    },
    watch: {
        $route() {
            this.checkArrowVisibility();
        }
    }
};
</script>

<style scoped>
.video-carousel-container {
    overflow: hidden;
}

.video-carousel {
    scroll-behavior: smooth;
    user-select: none;
    direction: rtl;
    /* Ensure RTL layout */
}

.video-carousel::-webkit-scrollbar {
    display: none;
}

.video-carousel {
    -ms-overflow-style: none;
    scrollbar-width: none;
}

.video-container {
    transition: transform 0.3s;
}

.video-container:active {
    transform: scale(0.95);
}

.cursor-grab {
    cursor: grab;
}

.cursor-grab:active {
    cursor: grabbing;
}

.left-arrow,
.right-arrow {
    background: none;
    border: none;
    cursor: pointer;
    opacity: 0.8;
    transition: opacity 0.5s;
    background-color: #000;
    color: white;
    border-radius: 30px;
    margin: 0 1rem;
}

.left-arrow:hover,
.right-arrow:hover {
    opacity: 1;
}

.left-arrow svg,
.right-arrow svg {
    width: 2rem;
    height: 2rem;
}
</style>
