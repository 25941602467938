<template>

  <div class=" min-h-screen w-full">
    <LoadingSpinner :is-loading="educationTypesLoading" />
    <div>
      <div class="w-full h-full flex flex-col content-center items-center justify-center" dir="rtl">

        <div class="w-[324px] md:w-[672px] absolute top-[12.25%] left-auto md:top-[13.8%] md:mb-[161px] md:flex md:justify-center">
          <ProgressBar progress="25"/>
        </div>
        <RequiredEducationTypeAndEducationStageMessage class="absolute top-[15.5%] left-[9%] md:left-[39%] md:top-[16.8%]"/>

        <Form class="w-[284px] md:w-[700px] absolute left-auto top-[26.5%] md:top-[32.35%]" @submit="onSubmit" action="">


          <div class="w-full mb-[6px]">
            <NameInput
                name="firstName"
                :rules="isValidFirstName"
                v-model="firstName"
                :styleClass="'p-2 pr-[17px] md:pr-[29px] border border-light-gray-custom w-[284px] md:w-[375px] h-[54px] rounded-[10px]'"
                id="firstName"
                placeholder="اكتب اسمك الأول بالعربي">
              <template v-slot:icon>
                <svgicon :data="userIcon" width="16" height="16" original :fill="false" class="absolute left-[12px] md:left-[75px]"/>
              </template>
            </NameInput>
          </div>


          <div class="w-full mb-[6px]">
            <NameInput
                name="lastName"
                :rules="isValidLastName"
                v-model="lastName"
                :styleClass="'p-2 pr-[17px] md:pr-[29px] border border-light-gray-custom w-[284px] md:w-[375px] h-[54px] rounded-[10px] text-right'"
                id="lastName"
                placeholder="اسم الأب والعائلة"
            >
              <template v-slot:icon>
                <svgicon :data="userIcon" width="16" height="16" original :fill="false" class="absolute left-[12px] md:left-[75px]"/>
              </template>
            </NameInput>
          </div>


          <div class="w-full">
            <div class=" flex flex-column justify-center">
              <PhoneNumberInput
                  v-model="parentPhone"
                  placeholder="رقم صحيح لولي الأمر"
                  :styleClass="'p-2 pr-[17px] md:pr-[29px] border border-light-gray-custom w-[284px] md:w-[375px] h-[54px] rounded-[10px] text-right'"
                  :rules="isValidParentPhone"
              >
                <template v-slot:icon>
                  <svgicon :data="smartPhoneIcon" width="24" height="24" original class="absolute left-[8px] md:left-[70px]"/>
                </template>
              </PhoneNumberInput>


            </div>
          </div>


          <div class="w-full">
            <div class=" flex flex-column justify-center">
              <SelectField
                  :rules="isValidEducationType"
                  name="eduType"
                  id="educationType"
                  v-model="eduType"
                  :options="educationTypes"
                  :validateOnModelUpdate="false"
                  :selectClass="'border border-light-gray-custom select-box decorated bg-white inputStyle mb-[25px] pr-[28px] p-2 w-[284px] md:w-[375px] h-[54px] m-auto rounded-[10px]'"
                  placeholder="اختر نوع التعليم"
                  @optionSelected="onOptionSelected('eduType')"
                  :allow-empty="false"
              />
            </div>
          </div>



          <div class="w-full mb-[171px] md:mb-[216px]">
            <div class=" flex flex-column justify-cente">

              <SelectField
                  :rules="educationStageRules"
                  name="eduStage"
                  id="educationStage"
                  v-model="eduStage"
                  :options="educationStages"
                  :validateOnModelUpdate="false"
                  :selectClass="'selector border border-light-gray-custom select-box decorated bg-white inputStyle mb-[25px] pr-[28px] p-2 w-[284px] md:w-[375px] h-[54px] m-auto rounded-[10px]'"
                  placeholder="اختر المرحلة الدراسية"
                  :allow-empty="false"
              />

            </div>
          </div>

          <BlueButton
              :customClass="'bg-blue-custom text-white text-[19px] font-bold rounded-[10px] w-[284px] h-[54px] flex justify-center items-center absolute top-[90.3%] md:top-[calc(100%-56px)] left-[0px] md:left-[30%]'"
              id="submitBtn"
              type="submit">
            التالي
          </BlueButton>

          <FromBackButton
              :customClass="'text-black text-[19px] font-medium w-[284px] md:w-[375px] h-[54px] m-auto absolute top-full md:left-[23%] left-[0%]'"
              id="formBackButtonAccountInfo"
              @click="handleGoBack"
              type="submit">
            الرجوع
          </FromBackButton>
        </Form>



      </div>
    </div>
  </div>

</template>

<script>
import {Form} from 'vee-validate';
import {getEducationTypes} from '@/graphql/queries/educationTypes/handlingCalls'
import ProgressBar from "@/components/FormComponents/ProgressBar.vue";
import NameInput from "@/components/FormComponents/NameInput.vue";
import {isValidFirstName} from "@/validations/isValidFirstName.js";
import {isValidLastName} from "@/validations/isValidLastName.js";
import {isValidParentPhone} from "@/validations/isValidPhone.js";
import PhoneNumberInput from "@/components/FormComponents/PhoneNumberInput.vue";
import SelectField from "@/components/FormComponents/SelectField.vue";
import BlueButton from "@/components/FormComponents/BlueButton.vue";
import routes from '@/router/routes.js';
import FromBackButton from "@/components/FormComponents/FormBackButton.vue";
import cookieEncryption from "@/helpers/cookieEncryption";
import {isValidEducationType} from "@/validations/isValidEducationType";
import {isValidEducationStage} from "@/validations/isValidEducationStage";
import RequiredEducationTypeAndEducationStageMessage
  from "@/components/FormComponents/RequiredEducationTypeAndEducationStageMessage.vue";
import userIcon from '@svgicon/user.svg';
import smartPhoneIcon from '@svgicon/smartphone.svg';

export default {
  name: 'signUpAccountInfo',
  components: {
    RequiredEducationTypeAndEducationStageMessage,
    FromBackButton,
    BlueButton,
    SelectField,
    PhoneNumberInput,
    NameInput,
    ProgressBar,
    Form,
  },
  computed: {
    educationStageRules() {
      return (value) => isValidEducationStage(value, this.educationTypes, this.eduType);
    },
  },
  data() {
    return {
      firstName: '',
      lastName: '',
      parentPhone: '',
      eduType: "",
      educationTypes: [],
      eduStage: "",
      educationStages: [],
      userIcon: userIcon,
      smartPhoneIcon: smartPhoneIcon,
      educationTypesLoading: false,

    }
  },
  methods: {
    isValidEducationStage,
    isValidEducationType,
    isValidFirstName,
    isValidLastName,
    isValidParentPhone,
    fetchEducationTypes() {
      const {educationTypes, loading} = getEducationTypes();

      this.$watch(
          () => educationTypes.value,
          (newVal) => {
            this.educationTypes = newVal;
          },
          {immediate: true}
      );

      this.$watch(
          () => loading.value,
          (newVal) => {
            this.educationTypesLoading = newVal;
            this.checkAndSetDefaultValues();

          },
          {immediate: true}
      );

    },
    checkAndSetDefaultValues() {
      if (!this.educationTypesLoading) {
        this.setDefaultValues();
      }
    },
    setDefaultValues() {
      const {
        studentFirstName,
        studentLastName,
        studentParentPhone,
        studentEducationType,
        studentEducationStage
      } = cookieEncryption.getCookie(this.$cookies, "elkhetaRegistrationData") || {};


      if (studentFirstName)
        this.firstName = studentFirstName;

      if (studentLastName)
        this.lastName = studentLastName;

      if (studentParentPhone)
        this.parentPhone = studentParentPhone;

      const defaultFields = [
        {field: 'eduType', value: studentEducationType, callback: 'eduType'},
        {field: 'eduStage', value: studentEducationStage, callback: 'eduStage'},
      ];

      defaultFields.forEach(({field, value, callback}) => {
        this[field] = value || '0';
        if (callback && value) {
          this.onOptionSelected(callback, false);
        }
      });


    },
    onOptionSelected(field, clearState = true) {
      switch (field) {
        case 'eduType':

          if (clearState) {
            this.resetFields('educationStages');

          }
          if (this.eduType) {
            const selectedType = this.educationTypes.find(
                (type) => type.id === this.eduType
            );

            this.educationStages = selectedType ? selectedType.educationStages : [];

          } else {
            this.educationStages = [];
          }
          break;
      }
    },
    resetFields(startField) {
      switch (startField) {
        case 'educationStages':
          this.eduStage = '0';
          this.educationStages = [];
          break;
      }
    },

    onSubmit() {

      const userData = {
        studentFirstName: this.firstName,
        studentLastName: this.lastName,
        studentParentPhone: this.parentPhone,
        studentEducationType: this.eduType,
        studentEducationStage: this.eduStage,
      };

      cookieEncryption.updateCookie(this.$cookies, "elkhetaRegistrationData", userData, 1);


      routes.navigateTo(routes.REGISTER_EDUCATION_INFO.key);

    },
    handleGoBack() {
      routes.navigateTo(routes.REGISTER.key);

    },
    onLoad() {
      this.fetchEducationTypes();

    },

  },

  beforeRouteEnter(to, from, next) {
    next(vm => {
      vm.onLoad();
    });
  },
  beforeRouteUpdate(to, from, next) {

    this.onLoad();
    next();
  },

}

</script>
