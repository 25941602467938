import router from '@/router';


const routes = {
    HOME: {
        key: 'HOME',
        name: 'Home',
        path: '/',
    },
    REGISTER: {
        key: 'REGISTER',
        name: 'Register',
        path: '/register',
    },
    REGISTER_OTP: {
        key: 'REGISTER_OTP',
        name: 'RegisterOTP',
        path: '/otp',
    },
    REGISTER_ACCOUNT_INFO: {
        key: 'REGISTER_ACCOUNT_INFO',
        name: 'RegisterAccountInfo',
        path: 'account-info',
    },
    REGISTER_EDUCATION_INFO: {
        key: 'REGISTER_EDUCATION_INFO',
        name: 'RegisterEducationInfo',
        path: 'education-info',
    },
    REGISTER_DONE: {
        key: 'REGISTER_DONE',
        name: 'RegisterDone',
        path: 'done',
    },
    LOGIN: {
        key: 'LOGIN',
        name: 'Login',
        path: '/login',
    },
    LOGOUT: {
        key: 'LOGOUT',
        name: 'Logout',
        path: '/logout',
    },
    FORGOT_PASSWORD: {
        key: 'FORGOT_PASSWORD',
        name: 'ForgotPassword',
        path: '/forgot-password',
    },
    RESET_PASSWORD: {
        key: 'RESET_PASSWORD',
        name: 'ForgotPasswordNewPassword',
        path: '/reset-password',
    },
    STUDY_CONTENT: {
        key: 'STUDY_CONTENT',
        name: 'studyContent',
        path: '/study-content',
    },
    MY_PROFILE: {
        key: 'MY_PROFILE',
        name: 'MainProfile',
        path: '/profile',
    },
    EDIT_MY_PROFILE: {
        key: 'EDIT_MY_PROFILE',
        name: 'EditProfile',
        path: '/edit-profile',
    },
    EDIT_MY_PASSWORD: {
        key: 'EDIT_MY_PASSWORD',
        name: 'ChangeNewPassword',
        path: '/edit-password',
    },
    EDIT_MY_PHONE_NUMBER: {
        key: 'EDIT_MY_PHONE_NUMBER',
        name: 'ChangePhoneNumber',
        path: '/edit-phone',
    },
    EDIT_MY_PHONE_NUMBER_VERIFICATION: {
        key: 'EDIT_MY_PHONE_NUMBER_VERIFICATION',
        name: 'ChangePhoneNumberVerification',
        path: '/edit-phone-otp',
    },
    SUBSCRIPTIONS: {
        key: 'SUBSCRIPTIONS',
        name: 'Subscription',
        path: '/subscriptions',
    },
    SUBSCRIPTIONS_PER_SUBJECT: {
        key: 'SUBSCRIPTIONS_PER_SUBJECT',
        name: 'SubscriptionPerSubject',
        path: '/subscriptions-per-subject',
    },
    CART: {
        key: 'CART',
        name: 'Cart',
        path: '/cart',
    },
    FAWRY_CHECKOUT: {
        key: 'FAWRY_CHECKOUT',
        name: 'FawryCheckout',
        path: '/fawry-checkout',
    },
    TERMS_OF_USE: {
        key: 'TERMS_OF_USE',
        name: 'TermsOfUse',
        path: '/terms-of-use',
    },
    ABOUT_US: {
        key: 'ABOUT_US',
        name: 'AboutUs',
        path: '/about-us',
    },
    REFUND_POLICY: {
        key: 'REFUND_POLICY',
        name: 'RefundPolicy',
        path: '/refund-policy',
    },
    PRIVACY_POLICY: {
        key: 'PrivacyPolicy',
        name: 'PrivacyPolicy',
        path: '/privacy-policy',
    },
    ASK_TEACHERS: {
        key: 'ASK_TEACHERS',
        name: 'AskTeacher',
        path: '/ask-teacher',
    },
    COURSE: {
        key: 'COURSE',
        name: 'course',
        path: '/courses/:courseSlug',
    },
    LESSON: {
        key: 'LESSON',
        name: 'lesson',
        path: '/courses/:courseSlug/lessons/:lessonSlug',
    },
    LESSON_PAPER_SUMMARY: {
        key: 'LESSON_PAPER_SUMMARY',
        name: 'PaperSummary',
        path: '/lessons/:lessonSlug/paper-summaries/:paperSummarySlug',
    },
    LESSON_VIDEO: {
        key: 'LESSON_VIDEO',
        name: 'Video',
        path: '/lessons/:lessonSlug/videos/:videoSlug',
    },
    LESSON_EXERCISE_INFO: {
        key: 'LESSON_EXERCISE_INFO',
        name: 'ExerciseInfo',
        path: '/lessons/:lessonSlug/exercises/:exerciseSlug',
    },
    LESSON_EXERCISE_START: {
        key: 'LESSON_EXERCISE_START',
        name: 'ExerciseStart',
        path: '/lessons/:lessonSlug/exercises/:exerciseSlug/start',
    },
    LESSON_EXERCISE_RESULT: {
        key: 'LESSON_EXERCISE_RESULT',
        name: 'ExerciseResult',
        path: '/lessons/:lessonSlug/exercises/:exerciseSlug/result',
    },


};

export default {
    ...routes,
    navigateTo(routeName, params = {}, query = {}) {
        const route = routes[routeName];
        if (route) {
            router.push({
                name: route.name,
                params,
                query,
            });
        } else {
            console.error(`Route '${routeName}' not found.`);
        }
    },
    navigateToCourse(courseSlug , query = {}) {
        this.navigateTo(routes.COURSE.key, { courseSlug } , query);
    },
    navigateToLesson(courseSlug, lessonSlug, query = {}) {
        this.navigateTo(routes.LESSON.key, { courseSlug, lessonSlug } , query);
    },
    navigateToPaperSummary(lessonSlug, paperSummarySlug, query = {}) {
        this.navigateTo(routes.LESSON_PAPER_SUMMARY.key, { lessonSlug, paperSummarySlug }, query);
    },
    navigateToExercise(lessonSlug, exerciseSlug, query = {}) {
        this.navigateTo(routes.LESSON_EXERCISE_INFO.key, { lessonSlug, exerciseSlug }, query);
    },
    navigateToExerciseStart(lessonSlug, exerciseSlug, query = {}) {
        this.navigateTo(routes.LESSON_EXERCISE_START.key, { lessonSlug, exerciseSlug }, query);
    },
    navigateToExerciseResult(lessonSlug, exerciseSlug, query = {}) {
        this.navigateTo(routes.LESSON_EXERCISE_RESULT.key, { lessonSlug, exerciseSlug }, query);
    },
    navigateToVideo(lessonSlug, videoSlug, query = {}) {
        this.navigateTo(routes.LESSON_VIDEO.key, { lessonSlug, videoSlug }, query);
    },
};