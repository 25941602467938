<template>
  <Line v-if="index" :index="index" />
  <div class="flex flex-row justify-between w-full mx-auto py-2 max-w-2xl px-[36px] md:px-[0]">
    <div class="flex flex-col gap-2 my-2 rounded-2xl w-full border shadow-sm cursor-pointer bg-white" :dir="direction">
      <p :class="textAlignClass" class="font-medium text-lg md:text-19px p-[10px] dynamic-align wrap-text"
        v-if="$isNotEmpty(questionBody)">
        {{ questionBody }}
      </p>
      <img class="rounded-2xl" v-if="$isNotEmpty(questionURL)" :src="questionURL">
    </div>
  </div>
</template>

<script>
import Line from "@/components/Exercise/Line.vue"

export default {
  name: "ExerciseQuestion",
  components: {
    Line
  },
  props: {
    question: {
      type: Object,
      required: true
    },
    index: {
      type: Number,
      required: false
    },
    isExam: {
      type: Boolean,
      required: true
    },
    direction: {
      type: String,
      required: true
    }
  },
  computed: {
    questionBody() {
      return this.question?.body || null;
    },
    questionURL() {
      return this.question?.full_photo_link || null;
    },
    textAlignClass() {
      if (this.isExam) {
        return this.direction === 'RTL' ? 'text-right' : 'text-left';
      } else {
        return 'text-center';
      }
    }
  }
};
</script>

<style scoped>
.wrap-text {
  white-space: normal;
  overflow-wrap: break-word;
  word-wrap: break-word;
}
</style>