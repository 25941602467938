<template>
  <div>
    <CorrectAnswerResult
      v-if="isCorrect"
      :dir="dir"
      :modelAnswerBody="modelAnswerBody"
      :modelAnswerURL="modelAnswerURL"
      :modelAnswerPhoto="modelAnswerPhoto"
      :isValidModelAnswer="isValidModelAnswer"
      :correctAnswerText="correctAnswerText"
      :explainAnswerText="explainAnswerText"
    />
    <IncorrectAnswerResult
      v-else
      :dir="dir"
      :answer="correctAnswer"
      :modelAnswerBody="modelAnswerBody"
      :modelAnswerURL="modelAnswerURL"
      :modelAnswerPhoto="modelAnswerPhoto"
      :isValidModelAnswer="isValidModelAnswer"
      :wrongAnswerText="wrongAnswerText"
      :explainAnswerText="explainAnswerText"
      :yourCorrectAnswerText="yourCorrectAnswerText"

    />
  </div>
</template>

<script>
import CorrectAnswerResult from '@/components/Exercise/Answers/CorrectAnswer.vue';
import IncorrectAnswerResult from '@/components/Exercise/Answers/IncorrectAnswer.vue';

export default {
  components: {
    CorrectAnswerResult,
    IncorrectAnswerResult,
  },
  props: {
    dir: {
      type: String,
      required: true,
    },
    correctAnswer: {
      type: Object ,
      required: true,
    },
    modelAnswer: {
      type: Object ,
      required: true,
    },
    isCorrect: {
      type: Boolean,
      required: true,
    },
    correctAnswerText: {
      type: String,
      required: true,
    },
    wrongAnswerText: {
      type: String,
      required: true,
    },
    explainAnswerText: {
      type: String,
      required: true,
    },
    yourCorrectAnswerText: {
      type: String,
      required: true,
    },

  },
  computed: {
    isValidModelAnswer(){
      return this.$isNotEmpty(this.modelAnswer) && (
          this.$isNotEmpty(this.modelAnswerURL)
          ||
          this.$isNotEmpty(this.modelAnswerBody)
          ||
          this.$isNotEmpty(this.modelAnswerPhoto)

      )
    },
    modelAnswerBody(){
      return this.modelAnswer?.body || '';
    },
    modelAnswerURL(){
      return this.modelAnswer?.url || '';
    },
    modelAnswerPhoto(){
      return this.modelAnswer?.full_photo_link || '';
    }
  },
}
</script>
