import { gql } from '@apollo/client/core';

export const REGISTER_USER = gql`
    mutation RegisterUser(
        $phone_number: String!,
        $password: String!,
        $password_confirmation: String!,
        $first_name: String!,
        $last_name: String,
        $parent_phone_number: String,
        $education_section_id: ID!,
        $course_class_sl_id: ID,
        $course_class_r_id: ID
    ) {
        registerUser(input: {
            phone_number: $phone_number,
            password: $password,
            password_confirmation: $password_confirmation,
            first_name: $first_name,
            last_name: $last_name,
            parent_phone_number: $parent_phone_number,
            education_section_id: $education_section_id,
            course_class_sl_id: $course_class_sl_id,
            course_class_r_id: $course_class_r_id
        }) {
            token
        }
    }
`;
