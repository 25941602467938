<template>
  <div>
    <LoadingSpinner :is-loading="educationTypesLoading || profileDataLoading || courseClassesLoading || isLoading"/>
    <BackButton id="backButton" class="absolute top-[89px] left-[85%] block md:hidden z-[1000]"/>

    <div class="bg-[#f4f6f8] flex flex-col items-center absolute w-full md:w-[calc(100%-260px)] h-full min-h-[1600px] md:min-h-[1500px]" dir="rtl">
        
      <router-link :to="{ name: 'ChangePhoneNumber' }" class="pointer-events-none cursor-not-allowed w-[90%] md:w-[80%] md:max-w-[680px] flex justify-center mt-[37.75%] md:mt-[205px]">
          <div
          class="w-full md:w-[662px] h-[54px] border-2 p-4 flex justify-between items-center rounded-[10px] bg-white cursor-pointer">
          <div class="flex w-[80%] justify-start items-center">
            <svgicon :data="gearIcon" width="20" height="20" original/>
            
            <div class="text-blue-custom mr-[30px]">تغيير رقم الهاتف</div>
            <div class="text-blue-custom mx-auto text-[16px] ">الرقم الحالي: {{currentPhoneNumber}}</div>
          </div>
          <div class="text-blue-custom">
            <ChevronLeft/>
          </div>
        </div>
      </router-link>
      
      
      <router-link :to="{ name: 'ChangeNewPassword' }" class=" w-[90%] md:w-[80%] md:max-w-[680px] flex justify-center">
        
        <div
        class="w-full md:w-[662px] h-[54px] border-2 p-4 flex justify-between items-center  rounded-[10px] mt-2  bg-white  cursor-pointer">
        <div class="flex w-[80%] md:w-[60%] justify-start items-center">
            <svgicon :data="gearIcon" width="20" height="20" original/>
            <div class="text-blue-custom mr-[30px]">تغيير الباسوورد</div>
          </div>
          <div class="text-blue-custom">
            <ChevronLeft/>
          </div>
        </div>
      </router-link>
      
      
      <div class="min-w-[90%] md:w-[80%] md:max-w-[662px] md:min-w-[408px] flex mt-8">
        <Form class="w-full flex flex-col justify-start items-start" @submit="onSubmit" action="">
          
          <p class="font-bold text-[14px] relative right-0 mb-2">تعديل المعلومات الشخصية</p>
          
          
          <div class="w-[95%] relative right-[5%]">
            <div class="flex flex-col items-start">
              <label for="firstName" class="text-[14px] mr-[16px]">اسمك الأول</label>
                <NameInput
                name="firstName"
                v-model="firstName"
                id="firstName"
                placeholder="اكتب اسمك الأول بالعربي"
                :rules="isValidFirstName"
                :containerFieldClass="'relative w-full md:w-auto md:max-w-[634px] m-auto rounded-[10px] flex justify-center items-center'"
                :styleClass="'pr-[17px] inputBorderStyle w-[100%] h-[54px] rounded-[10px]'"
                :errorClass="'text-[#C50000] w-[284px] md:w-full text-right pr-2'"
                >
                <template v-slot:icon>
                  <svgicon :data="userIcon" width="16" height="16" original :fill="false" class="absolute left-[14px] md:left-[14px]"/>
                </template>
              </NameInput>
            </div>
          </div>

          
          <div class="w-[95%] relative right-[5%]">
            <div class="flex flex-col items-start">
              <label for="lastName" class="text-[14px] mr-[16px]">اسم الأب واللقب</label>
                <NameInput
                name="lastName"
                v-model="lastName"
                id="lastName"
                placeholder="اسم الأب والعائلة"
                :rules="isValidLastName"
                :containerFieldClass="'relative w-full md:w-auto md:max-w-[634px] m-auto rounded-[10px] flex justify-center items-center'"
                :styleClass="'pr-[17px] inputBorderStyle w-[100%] h-[54px] rounded-[10px]'"
                :errorClass="'text-[#C50000] w-[284px] md:w-full text-right pr-2'"
                >
                <template v-slot:icon>
                  <svgicon :data="userIcon" width="16" height="16" original :fill="false" class="absolute left-[14px] md:left-[14px]"/>
                </template>
              </NameInput>
          </div>
        </div>
        
        
        <div class="w-[95%] relative right-[5%]">
          <div class="flex flex-col items-start">
            <label for="phone" class="text-[14px] mr-[16px]">رقم ولي الأمر</label>
              <PhoneNumberInput
                    v-model="parentPhone"
                    placeholder="رقم صحيح لولي الأمر"
                    :rules="isValidParentPhone"
                    :containerFieldClass="'relative w-full md:w-auto md:max-w-[634px] m-auto rounded-[10px] flex justify-center items-center'"
                    :styleClass="'pr-[17px] inputBorderStyle w-[100%] h-[54px] rounded-[10px]'"
                    :errorClass="'text-[#C50000] w-[284px] md:w-full text-right'"
                    >
                    <template v-slot:icon>
                      <svgicon :data="smartPhoneIcon" width="24" height="24" original class="absolute left-[14px] md:left-[14px]"/>
                    </template>
                  </PhoneNumberInput>
              </div>
            </div>
            
            
            <div class="w-[95%] mb-7 relative right-[5%]">
            <div class="flex flex-col items-start">
              <label for="firstName" class="text-[14px] mr-[16px]">البريد الإلكتروني</label>
                <EmailInput
                name="email"
                v-model="email"
                id="email"
                :rules="isValidEmail"
                :containerFieldClass="'relative w-full md:w-auto md:max-w-[634px] m-auto rounded-[10px] flex justify-center items-center'"
                :styleClass="'pr-[17px] inputBorderStyle w-[100%] h-[54px] rounded-[10px]'"
                :errorClass="'text-[#C50000] w-[284px] md:w-full text-right pr-2'"
                >
                <template v-slot:icon>
                    <svgicon :data="smartPhoneIcon" width="24" height="24" original class="absolute left-[14px] md:left-[14px]"/>
                  </template>
                </EmailInput>
            </div>
          </div>
          
          
          
          <p class="font-bold text-[14px] relative right-0 mb-2">تعديل المعلومات الدراسية</p>
          
          
          <div class="w-[95%] mb-7 relative right-[5%]">
            <div class="flex flex-col justify-start items-start">
              <div class="relative w-[100%] h-[54px]">
                <SelectField
                :rules="isValidEducationType"
                name="eduType"
                id="educationType"
                v-model="eduType"
                :options="educationTypes"
                placeholder="اختر نوع التعليم"
                @optionSelected="onOptionSelected('eduType')"
                :allow-empty="false"
                :errorClass="'text-[#C50000] w-full m-auto text-right pr-2 mt-1 mb-1'"
                :containerFieldClass="'relative w-full md:w-auto md:max-w-[634px] h-[54px] rounded-[10px] flex justify-center items-center'"
                :selectClass="'border border-light-gray-custom select-box decorated bg-white  mb-[25px] pr-[0px] indent-1.5 w-[100%] h-[54px] m-auto rounded-[10px]'"
                />
              </div>
            </div>
          </div>
          
          
          <div class="w-[95%] mb-7 relative right-[5%]">
            <div class="flex flex-col justify-start items-start">
              <div class="relative w-[100%] h-[54px]">
                <SelectField
                :rules="educationStageRules"
                name="eduStage"
                id="educationStage"
                v-model="eduStage"
                :options="educationStages"
                :validateOnModelUpdate="false"
                    @optionSelected="onOptionSelected('eduStage')"
                    placeholder="اختر المرحلة الدراسية"
                    :allow-empty="false"
                    :errorClass="'text-[#C50000] w-full m-auto text-right pr-2 mt-1 mb-1'"
                    :containerFieldClass="'relative w-full md:w-auto md:max-w-[634px] h-[54px] rounded-[10px] flex justify-center items-center'"
                    :selectClass="'selector border border-light-gray-custom select-box decorated bg-white inputStyle indent-1.5 w-[100%] h-[54px] rounded-[10px]'"
                    />
                  </div>
                </div>
              </div>
              
              
              <div class="w-[95%] mb-7 relative right-[5%]">
                <div class="flex flex-col justify-start items-start">
                  <div class="relative w-[100%] h-[54px]">
                    <SelectField
                    :rules="educationClassRules"
                    name="eduClass"
                    id="educationClass"
                    v-model="eduClass"
                    :options="educationClasses"
                    placeholder="اختر السنة الدراسية"
                    @optionSelected="onOptionSelected('eduClass')"
                    :allow-empty="false"
                    :errorClass="'text-[#C50000] w-full m-auto text-right pr-2 mt-1 mb-1'"
                    :containerFieldClass="'relative w-full md:w-auto md:max-w-[634px] h-[54px] rounded-[10px] flex justify-center items-center'"
                    :selectClass="'selector border border-light-gray-custom select-box decorated bg-white inputStyle indent-1.5 w-[100%] h-[54px] rounded-[10px]'"
                    />
                  </div>
            </div>
          </div>
          
          
          <div class="w-[95%] mb-7 relative right-[5%]">
            <div class="flex flex-col justify-start items-start">
              <div class="relative w-[100%] h-[54px]">
                <SelectField
                :rules="educationSectionRules"
                name="eduSection"
                id="educationSection"
                v-model="eduSection"
                :options="educationSections"
                :validateOnModelUpdate="false"
                placeholder="اختر القسم الدراسي"
                :allow-empty="false"
                :errorClass="'text-[#C50000] w-full m-auto text-right pr-2 mt-1 mb-1'"
                :containerFieldClass="'relative w-full md:w-auto md:max-w-[634px] h-[54px] rounded-[10px] flex justify-center items-center'"
                :selectClass="'selector border border-light-gray-custom select-box decorated bg-white inputStyle indent-1.5 w-[100%] h-[54px] rounded-[10px]'"
                />
              </div>
            </div>
          </div>
          
          
          <div class="w-[95%] mb-7 relative right-[5%]">
            <div class="flex flex-col justify-start items-start">
              <div class="relative w-[100%] h-[54px]">
                <SelectField
                :rules="secondaryLanguageRules"
                name="eduSecondaryLanguage"
                id="eduSecondaryLanguage"
                v-model="eduSecondaryLanguage"
                :options="secondaryLanguages"
                    placeholder="اختر اللغة الثانية"
                    :allow-empty="true"
                    :errorClass="'text-[#C50000] w-full md:w-full m-auto text-right pr-2 mt-1 mb-1'"
                    :containerFieldClass="'relative w-full md:w-auto md:max-w-[634px] h-[54px] rounded-[10px] flex justify-center items-center'"
                    :selectClass="'selector border border-light-gray-custom select-box decorated bg-white inputStyle indent-1.5 w-[100%] h-[54px] rounded-[10px]'"
                    />
                  </div>
                </div>
              </div>
              
              
              <div class="w-[95%] mb-7  relative right-[5%]">
                <div class="flex flex-col justify-start items-start">
                  <div class="relative w-[100%] h-[54px]">
                    <SelectField
                    :rules="religionSubjectsRules"
                    name="eduReligionSubject"
                    id="eduReligionSubject"
                    v-model="eduReligionSubject"
                    :options="religionSubjects"
                    placeholder="اختر مادة التربية الدينية"
                    :allow-empty="true"
                    :errorClass="'text-[#C50000] w-full md:w-full m-auto text-right pr-2 mt-1 mb-1'"
                    :containerFieldClass="'relative w-full md:w-auto md:max-w-[634px] h-[54px] rounded-[10px] flex justify-center items-center'"
                    :selectClass="'selector border border-light-gray-custom select-box decorated bg-white inputStyle indent-1.5 w-[100%] h-[54px] rounded-[10px]'"
                    />
                  </div>
                </div>
                
                <div v-if="errors.length > 0" class="text-[#C50000] w-[284px] md:w-[375px] m-auto text-right pr-2 mt-1">
                  <span v-for="(error, index) in errors" :key="index">
                    {{ error }}
                  </span>
                </div>
              </div>
              
              <BlueButton
              class="mb-4" 
              :customClass="'w-[284px] relative h-[35px] m-auto rounded-[20px] flex justify-center items-center bg-blue-custom text-white font-bold'"
              id="submitBtn"
              type="submit">
              حفظ التعديلات
            </BlueButton>

          </Form>
        
        </div>
      </div>
    </div>
</template>

<script>
import ChevronLeft from '@/components/Icons/ChevronLeftIcon.vue'
import BackButton from '@/components/FormComponents/BackButton.vue';
import {Form} from 'vee-validate';
import {updateUser} from '@/graphql/mutations/updateUser/handlingCalls';
import userIcon from '@svgicon/user.svg';
import gearIcon from '@svgicon/gear.svg';
import smartPhoneIcon from '@svgicon/smartphone.svg';
import NameInput from "@/components/FormComponents/NameInput.vue";
import EmailInput from "@/components/FormComponents/EmailInput.vue";
import {isValidEducationStage} from "@/validations/isValidEducationStage";
import {isValidEducationType} from "@/validations/isValidEducationType";
import {isValidFirstName} from "@/validations/isValidFirstName";
import {isValidLastName} from "@/validations/isValidLastName";
import {isValidParentPhone} from "@/validations/isValidPhone";
import {isValidEducationClass} from "@/validations/isValidEducationClass";
import {isValidEducationSection} from "@/validations/isValidEducationSection";
import {isValidSecondaryLanguage} from "@/validations/isValidSecondaryLanguage";
import {isValidReligionSubject} from "@/validations/isValidReligionSubject";
import {isValidEmail} from "@/validations/isValidEmail";
import PhoneNumberInput from "@/components/FormComponents/PhoneNumberInput.vue";
import SelectField from "@/components/FormComponents/SelectField.vue";
import BlueButton from "@/components/FormComponents/BlueButton.vue";
import {getEducationTypesWithClasses} from "@/graphql/queries/educationTypes/handlingCalls";
import {getEditProfileData} from "@/graphql/queries/myProfile/handlingCalls";
import {getAllCourseCLasses} from "@/graphql/queries/courseClasses/handlingCalls";
import LoadingSpinner from "@/components/General/LoadingSpinner.vue";

export default {
  name: "EditProfilePage",
  components: {
    LoadingSpinner,
    BlueButton,
    SelectField,
    PhoneNumberInput,
    NameInput,
    EmailInput,
    ChevronLeft,
    Form,
    BackButton
  },
  data() {
    return {
      userIcon: userIcon,
      gearIcon: gearIcon,
      smartPhoneIcon: smartPhoneIcon,
      firstName: '',
      lastName: '',
      parentPhone: '',
      currentPhoneNumber: '',
      email: '',
      eduType: '',
      educationTypes: [],
      eduStage: '',
      educationStages: [],
      eduClass: '',
      educationClasses: [],
      eduSection: '',
      educationSections: [],
      eduSecondaryLanguage: '',
      secondaryLanguages: [],
      eduReligionSubject: '',
      religionSubjects: [],
      myProfileData: [],
      educationTypesLoading: false,
      profileDataLoading: false,
      courseClassesLoading: false,
      isLoading: false,
      errors: [],
    }
  },
  computed: {
    educationStageRules() {
      return (value) => isValidEducationStage(value, this.educationTypes, this.eduType);
    },
    educationClassRules() {
      return (value) => isValidEducationClass(value, this.educationClasses, this.eduClass);
    },
    educationSectionRules() {
      return (value) => isValidEducationSection(value, this.educationClasses, this.eduClass);
    },
    secondaryLanguageRules() {
      return (value) => isValidSecondaryLanguage(value, this.secondaryLanguages, this.eduSecondaryLanguage);
    },
    religionSubjectsRules() {
      return (value) => isValidReligionSubject(value, this.religionSubjects, this.eduReligionSubject);
    },
  },
  methods: {
    isValidEducationStage,
    isValidEducationType,
    isValidFirstName,
    isValidLastName,
    isValidParentPhone,
    isValidEducationClass,
    isValidEducationSection,
    isValidSecondaryLanguage,
    isValidReligionSubject,
    isValidEmail,
    fetchEducationTypes() {
      const {educationTypes, loading} = getEducationTypesWithClasses();

      this.$watch(
          () => educationTypes.value,
          (newVal) => {
            this.educationTypes = newVal;
          },
          {immediate: true}
      );

      this.$watch(
          () => loading.value,
          (newVal) => {
            this.educationTypesLoading = newVal;
            this.checkAndSetDefaultValues();
          },
          {immediate: true}
      );

    },
    onOptionSelected(field, clearState = true) {
      const fieldMap = {
        eduType: {
          value: this.eduType,
          options: this.educationTypes,
          resultField: 'educationStages',
          resetFields: ['educationStages', 'educationClasses', 'educationSections'],
        },
        eduStage: {
          value: this.eduStage,
          options: this.educationStages,
          resultField: 'educationClasses',
          resetFields: ['educationClasses', 'educationSections'],
        },
        eduClass: {
          value: this.eduClass,
          options: this.educationClasses,
          resultField: 'educationSections',
          resetFields: ['educationSections'],
        },
      };

      const {value, options, resultField, resetFields} = fieldMap[field];
      if (clearState) {
        resetFields.forEach((resetField) => {
          this.resetFields(resetField);
        });
      }
      this[resultField] = value ? options.find((option) => option.id === value)?.[resultField] || [] : [];
    },
    resetFields(startField) {
      const fieldMap = {
        educationStages: ['eduStage', 'educationStages'],
        educationClasses: ['eduClass', 'educationClasses'],
        educationSections: ['eduSection', 'educationSections'],
      };
      const fields = fieldMap[startField];
      if (fields) {
        fields.forEach((field) => {
          this[field] = Array.isArray(this[field]) ? [] : '0';
        });
      }
    },
    fetchEditProfileData() {
      const {profileData, loading} = getEditProfileData();
      this.$watch(
          () => profileData.value,
          (newVal) => {
            this.myProfileData = newVal;
            this.currentPhoneNumber = this.myProfileData.phone_number
            this.updatePersonalData();
          },
          {immediate: true}
      );
      this.$watch(
          () => loading.value,
          (newVal) => {
            this.profileDataLoading = newVal;
            this.checkAndSetDefaultValues();
          },
          {immediate: true}
      );
    },
    fetchCourseClasses() {
      const {secondaryLanguageClasses, religionClasses, loading} = getAllCourseCLasses();
      this.$watch(
          () => secondaryLanguageClasses.value,
          (newVal) => {
            this.secondaryLanguages = newVal;
          },
          {immediate: true}
      );
      this.$watch(
          () => religionClasses.value,
          (newVal) => {
            this.religionSubjects = newVal;
          },
          {immediate: true}
      );
      this.$watch(
          () => loading.value,
          (newVal) => {
            this.courseClassesLoading = newVal;
            this.checkAndSetDefaultValues();
          },
          {immediate: true}
      );
    },

    checkAndSetDefaultValues() {
      if (!this.educationTypesLoading && !this.profileDataLoading && !this.courseClassesLoading) {
        this.setDefaultValues();
      }
    },
    setDefaultValues() {
      const {
        educationTypes, educationStage, educationClass, educationSection, courseClassSecondaryLanguage,
        courseClassReligion
      } = this.myProfileData || {};

      const defaultFields = [
        {field: 'eduType', value: educationTypes?.[0]?.id, callback: 'eduType'},
        {field: 'eduStage', value: educationStage?.id, callback: 'eduStage'},
        {field: 'eduClass', value: educationClass?.id, callback: 'eduClass'},
        {field: 'eduSection', value: educationSection?.id},
        {field: 'eduSecondaryLanguage', value: courseClassSecondaryLanguage?.id},
        {field: 'eduReligionSubject', value: courseClassReligion?.id}
      ];
      defaultFields.forEach(({field, value, callback}) => {
        this[field] = value || '0';
        if (callback && value) {
          this.onOptionSelected(callback, false);
        }
      });

    },
    updatePersonalData() {
      const {name, email, personalData} = this.myProfileData || {};
      this.firstName = name || '';
      this.lastName = personalData?.last_name || '';
      this.parentPhone = personalData?.parent_phone_number || '';
      this.email = email || '';

    },
    async onSubmit() {
      this.errors = [];
      const userData = {
        first_name: this.firstName,
        last_name: this.lastName || '',
        email: this.email || '',
        parent_phone_number: this.parentPhone || '',
        education_section_id: this.eduSection,
        ...(this.eduSecondaryLanguage && this.eduSecondaryLanguage !== '0' && this.eduSecondaryLanguage !== 0 && {course_class_sl_id: this.eduSecondaryLanguage}),
        ...(this.eduReligionSubject && this.eduReligionSubject !== '0' && this.eduReligionSubject !== 0 && {course_class_r_id: this.eduReligionSubject}),
      }

      const updateUserResponse = await this.$withLoading(() => updateUser(userData));
      if (updateUserResponse.success === false){
        this.errors = updateUserResponse.displayValidationMessages;
      }

    },
    onLoad() {
      this.fetchEducationTypes();
      this.fetchEditProfileData();
      this.fetchCourseClasses();
    }
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      vm.onLoad();
    });
  },
  beforeRouteUpdate(to, from, next) {
    this.onLoad();
    next();
  },
}
</script>