<template>
  <div class="flex flex-column min-h-screen items-center">
    <div class="mt-[40px] w-full px-4" :dir="direction">
      <div class="fixed-content">
        <div class="flex flex-row gap-3 items-center align-bottom">
          <XIcon
              :width="headerWidth"
              :height="headerHeight"
              color="text-gray-icon"
              @x-icon-clicked="handleXIconClicked"
          />
          <h1 class="text-black-custom md:text-blue-custom text-xs md:text-17px ">
            {{ name }}
          </h1>
        </div>
        <div class="flex flex-row gap-2 items-center">
          <HeaderCounter :all="totalCount" :counter="currentIndex" v-if="!isMobile"/>
          <CheckedIcon :whiteColor="!isCompleted" :inHeader="true" />
        </div>
      </div>
    </div>

    <div class="mt-10 rounded-lg mx-auto flex-1 w-full md:w-[668px] px-4" :dir="direction">
      <div class="video-frame" v-html="url"></div>
      <div class="mt-4 dynamic-align" v-html="content"></div>

    </div>
    <div>

    </div>
    <FooterBlur class="p-4" v-if="isMobile">
      <MobileNavigationButtons
          :dir="direction"
          :nextText="nextText"
          :isValidPrevious="isValidPrevious"
          :isValidNext="isValidNext"
          :previousButtonWidth="'35px'"
          :previousButtonHeight="'35px'"
          :nextButtonWidth="'277px'"
          :nextButtonHeight="'35px'"
          @back-clicked="handleBackClicked"
          @next-clicked="handleNextClicked"
      />
    </FooterBlur>

    <FooterBlur class="p-4" v-else>
      <NavigationButtons
          :width="'w-[668px]'"
          :dir="direction"
          :previousText="previousText"
          :nextText="nextText"
          :isValidNext="isValidNext"
          :isValidPrevious="isValidPrevious"
          @back-clicked="handleBackClicked"
          @next-clicked="handleNextClicked"
      />
    </FooterBlur>
  </div>
</template>

<script>
import CheckedIcon from "@/components/Icons/CheckedIcon.vue";
import XIcon from "@/components/Icons/XBackIcon.vue";
import FooterBlur from "@/components/General/FooterSection.vue";
import routeParamsMixin from "@/mixins/routeParams";
import contentDataMixin from "@/mixins/contentData";
import windowSizeMixin from "@/mixins/windowSize";
import HeaderCounter from "@/components/General/HeaderCounter.vue";
import cookieEncryption from "@/helpers/cookieEncryption";
import { markVideoAsCompleted } from "@/graphql/mutations/markVideoAsCompleted/handlingCalls";
import NavigationButtons from "@/components/General/NavigationButtons.vue";
import MobileNavigationButtons from "@/components/General/MobileNavigationButtons.vue";

export default {
  name: "VideoContent",
  mixins: [routeParamsMixin, contentDataMixin, windowSizeMixin],

  components: {
    HeaderCounter,
    CheckedIcon,
    XIcon,
    FooterBlur,
    MobileNavigationButtons,
    NavigationButtons,
  },
  props: {
    contentDataProp: {
      type: String,
      default: "video",
    },
    contentErrorDataProp: {
      type: String,
      default: "videoError",
    },
    video: {
      type: Object,
      required: true,
    },
    videoError: {
      type: [Object, Array, String, null],
      required: true,
    },
  },

  data() {
    return {
      watchTimePercentage: 0,
      videoCompleted: false,
      trackingInterval: null,
    };
  },
  computed: {
    name() {
      return this.video?.name || "";
    },
    content() {
      return this.video?.content || "";
    },
    url() {
      return this.video?.url || "";
    },
    videoId() {
      return this.video?.id || "";
    },
    durationInMinutes() {
      return this.video?.duration_in_minutes || 5; //default to 5 minutes
    },

    headerWidth() {
      return this.isMobile ? "24" : "37";
    },
    headerHeight() {
      return this.isMobile ? "24" : "37";
    },
  },

  methods: {
    async updateUserProgress() {
      await markVideoAsCompleted(this.videoId);
    },
    getVideoCookieName() {
      return "video_" + this.videoId;
    },
    getTrackingData() {
      const videoCookieName = this.getVideoCookieName();
      const trackedVideos =
          cookieEncryption.getCookie(this.$cookies, "elkhetaTrackedVideos") || {};
      return trackedVideos[videoCookieName] || null;
    },
    updateTrackingData(progress, completed) {
      const videoCookieName = this.getVideoCookieName();
      const trackingData = {
        progress,
        completed,
      };

      cookieEncryption.updateCookie(
          this.$cookies,
          "elkhetaTrackedVideos",
          {
            [videoCookieName]: trackingData,
          },
          300
      );

      if (completed) {
        this.updateUserProgress();
      }
    },
    initializeTracking() {
      const trackingData = this.getTrackingData();
      this.videoCompleted = trackingData?.completed || false;

      if (!this.videoCompleted) {
        this.watchTimePercentage = trackingData?.progress || 0;
        this.startTrackingWatchTime();
      }
    },
    startTrackingWatchTime() {
      const videoDuration = this.durationInMinutes * 60;
      let elapsedTime = (this.watchTimePercentage / 100) * videoDuration;

      this.trackingInterval = setInterval(() => {
        elapsedTime++;
        this.watchTimePercentage = (elapsedTime / videoDuration) * 100;

        if (this.watchTimePercentage >= 60 && !this.videoCompleted) {
          this.markVideoAsCompleted();
          clearInterval(this.trackingInterval);
        }
      }, 1000);
    },
    markVideoAsCompleted() {
      this.videoCompleted = true;
      this.updateTrackingData(100, true);
    },
    saveProgress() {
      this.updateTrackingData(this.watchTimePercentage, this.videoCompleted);
    },
  },
  watch: {
    video(newVal) {
      if (this.$isNotEmpty(newVal)) {
        this.initializeTracking();
      }
    },
  },
  mounted() {
    window.addEventListener("beforeunload", this.saveProgress);
  },

  beforeUnmount() {
    window.removeEventListener("beforeunload", this.saveProgress);
    this.saveProgress();
    clearInterval(this.trackingInterval);
  },
};
</script>

<style scoped>
/* Add any necessary styles here */
</style>
