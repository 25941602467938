import cookieEncryption from '@/helpers/cookieEncryption';

const cookieName = 'elkhetaAuthToken';
const expirationDays = 30;

export const loginCookie =  {
    setAuthToken(cookies, token)  {
        cookieEncryption.setCookie(cookies, cookieName, token, expirationDays);
    },

    getAuthToken(cookies) {
        return cookieEncryption.getCookie(cookies, cookieName);
    },

     removeAuthToken(cookies) {
        cookieEncryption.deleteCookie(cookies, cookieName);
    },

     isLoggedIn(cookies) {
        const authToken = this.getAuthToken(cookies);
        return !!authToken;
    }
}
