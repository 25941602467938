import {gql} from "@apollo/client/core";
import {navigationFragment} from '@/graphql/fragments/navigationFragments';

export const EXERCISE_CONTENT = gql`
    query GetExerciseContent($slug: String!){
        exerciseContent(input: {
            slug: $slug
        }) {
            id
            name
            slug
            exercise_time
            is_exam
            is_completed
            number_of_questions
            difficulty_level
            is_already_started_solution
            completed_exercise_sessions {
                id
                score
                start_time
                end_time
                formatted_time
            }
            navigation {
                ...LessonContentNavigation
            }
        }
    }
    ${navigationFragment}

`;

export const EXERCISE_RESULT_CONTENT = gql`
    query GetExerciseResultContent($slug: String!){
        exerciseResultContent(input: {
            slug: $slug
        }) {
            grade
            time
            exercise {
                id
                name
                slug
                is_exam
                is_completed
                direction
                navigation {
                    ...LessonContentNavigation
                }
            }
        }
    }
    ${navigationFragment}

`;
