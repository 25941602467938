import { gql } from '@apollo/client/core';

export const STUDY_CONTENT = gql`
    query GetStudyContent{
        studyContent {
            tabs {
                id
                name
                courses {
                    name
                    slug
                    progress
                    teacher {
                        name
                    }
                    photo {
                        full_link
                    }
                    lessonPhoto {
                        full_link
                    }
                }
                icon {
                    full_link
                }
            }
        }
    }
`;
