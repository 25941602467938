<template>
  <div class="bg-blue">
    <div class="flex flex-column min-h-screen items-center">
      <div class="w-full mx px-4 pt-[80px] md:pt-[40px]" :dir="direction">
        <ExerciseHeader class="fixed-content" :gap="'gap-4'" :exerciseTitle="name" @x-icon-clicked="handleXIconClicked" :isWhite="true"
          textColor="white">
          <div class="flex align-center gap-3">
            <svgicon :data="Checked" :width="24" :height="24" original :fill="false" />
          </div>
        </ExerciseHeader>
      </div>

      <div class="flex-1 mx-auto px-4 w-full max-w-xl mt-[5rem] md:mt-[10rem] items-center" :dir="direction">
        <TimeExceededError />
        <div class="flex flex-col items-center">
          <svgicon :data="Badge" :width="153" :height="153" original />
          <ResultInfo :resultGradeText="resultGradeText" :resultTimeText="resultTimeText" :grade="resultGrade"
            :time="resultTime" />
        </div>
        <div class="underline cursor-pointer flex flex-row justify-center items-center gap-1 mt-12"
          @click="handleRetakeQuiz">
          {{ RetakeQuizText }}
          <svgicon :data="Retake" :width="24" :height="24" original :fill="false" />
        </div>
      </div>

      <FooterBlur class="p-4" v-if="isMobile">
        <div class="max-w-3xl mx-auto">
          <div class="grid grid-cols-12 gap-4 text-center items-center">
            <div class="col-span-1"></div>
            <span @click="handleNextClicked"
              class="col-span-10 py-1 rounded-full bg-blue-custom text-white-custom font-bold-600">
              {{ nextText }}
            </span>
            <div class="col-span-1"></div>
          </div>
        </div>
      </FooterBlur>

      <FooterBlur class="p-4" v-else>
        <NavigationButtons :previousText="previousText" :nextText="nextText" :isValidNext="isValidNext"
          :isValidPrevious="isValidPrevious" @back-clicked="handleBackClicked" @next-clicked="handleNextClicked" />
      </FooterBlur>

    </div>
  </div>
</template>

<script>
import ExerciseHeader from "@/components/General/Header.vue";
import ResultInfo from "@/components/Exercise/ResultInfo.vue";
import Badge from "@/assets/svg/badge.svg"
import Retake from "@/assets/svg/Refresh.svg"
import routeParamsMixin from "@/mixins/routeParams";
import exerciseContentDataMixin from "@/mixins/exerciseContentData";
import windowSizeMixin from "@/mixins/windowSize";
import routes from "@/router/routes";
import TimeExceededError from "@/components/General/TimeExceededError.vue";
import Checked from "@/assets/svg/white-checked-mark.svg"
import FooterBlur from "@/components/General/FooterSection.vue";
import NavigationButtons from "@/components/General/NavigationButtons.vue";



export default {
  mixins: [routeParamsMixin, exerciseContentDataMixin, windowSizeMixin],

  name: "ResultPage",
  components: {
    TimeExceededError,
    ExerciseHeader,
    ResultInfo,
    FooterBlur,
    NavigationButtons,

  },
  props: {
    contentDataWrapperProp: {
      type: String,
      default: 'exerciseContent'
    },
    contentDataProp: {
      type: String,
      default: 'exercise'
    },
    contentErrorDataProp: {
      type: String,
      default: 'exerciseError'
    },
    exerciseContent: {
      type: Object,
      required: true
    },
    exercise: {
      type: Object,
      required: true
    },
    exerciseError: {
      type: [Object, Array, String, null],
      required: true
    }
  },

  data() {
    return {
      Badge,
      Retake,
      Checked
    }
  },
  computed: {
    name() {
      return this.exercise?.name || '';
    },
    resultGrade() {
      return String(this.exerciseContent?.grade ?? '');
    },
    resultTime() {
      return this.exerciseContent?.time || '';
    }
  },

  methods: {
    handleRetakeQuiz() {
      routes.navigateToExerciseStart(this.lessonSlug, this.exerciseSlug)

    },
  }
}
</script>

<style scoped>
.fixed-content{
  background-color: #2196F3 !important;
  color: white;
  top: 64px !important;
}
</style>