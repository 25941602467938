export default {
    props: {
        answer: {
            type: Object,
            required: true,
        },
    },
    computed: {
        answerLabel(){
            return this.answer?.label_letter || '';
        },
        answerBody(){
            return this.answer?.body || '';
        },
        answerType(){
            return this.answer?.answer_type || '';
        },
        isTextAnswer(){
            return this.answerType === 'ALPHABET';
        },
        isTextAndPhotoAnswer(){
            return this.answerType === 'TEXT_PHOTO';
        },
        isNumericAnswer(){
            return this.answerType === 'NUMERIC';
        },
        answerIsCorrect(){
            return this.answer?.is_correct || false;
        },
        answerPhoto(){
            return this.answer?.full_photo_link || '';
        },
        answerId(){
            return this.answer?.id || 0;

        }
    },


}