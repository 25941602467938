<template>
  <div class="bg-circle-gray md:pt-14">
    <div class="w-10/12 md:w-11/12 mx-auto">
      <div class="dynamic-align mb-4">
        <img :src="logo"  :width="isMobile ? 126 : 219" :height="isMobile ? 54 : 94" />
      </div>
      <div v-if="isMobile" class="text-xs">
        <div v-for="(item, index) in filteredItems" :key="index">
          <FooterLinksSection  :title="item.title" :item="item" />
        </div>
      </div>
      <div class="grid grid-cols-6 gap-4 text-sm">
        <div
          v-for="(item, index) in items"
          :key="index"
          class="col-span-3 mx-auto lg:col-span-1 flex flex-col items-start mt-[40px] lg:mt-[10px]"
        >
          <FooterSection
            v-if="item.forMobile === false || !isMobile"
            :item="item"
          />
        </div>
      </div>
      <div class="hr mt-[5%]">
        <CopyrightNotice />
      </div>
    </div>
  </div>
</template>

<script>
import logo from "@/assets/images/landing/logo.png";
import FooterSection from "@/components/Footer/FooterSection.vue";
import FooterLinksSection from "@/components/Footer/FooterLinksSection.vue";
import CopyrightNotice from "@/components/Footer/CopyrightNotice.vue";
import windowSizeMixin from '@/mixins/windowSize';


export default {
  name: "LandingFooter",
  mixins: [windowSizeMixin],
  data() {
    return {
      logo,
      items: [
        {
          title: "قنوات التواصل للابتدائى",
          forMobile: false,
          links: [
            {
              text: "المرحلة الابتدائية",
              url: "#",
              type: "facebook",
            },
            {
              text: "المرحلة الابتدائية",
              url: "#",
              type: "instagram",
            },
            {
              text: "المرحلة الابتدائية",
              url: "#",
              type: "tiktok",
            },
            {
              text: "الرابع الإبتدائى",
              url: "#",
              type: "youtube",
            },
            {
              text: "الخامس الإبتدائى",
              url: "#",
              type: "youtube",
            },
            {
              text: "السادس الإبتدائى",
              url: "#",
              type: "youtube",
            },
          ],
        },
        {
          title: "قنوات التواصل لإعدادى",
          forMobile: false,
          links: [
            {
              text: "المرحلة الإعدادية",
              url: "https://www.facebook.com/elkheta.middle",
              type: "facebook",
            },
            {
              text: "المرحلة الإعدادية",
              url: "https://www.instagram.com/elkheta.middle",
              type: "instagram",
            },
            {
              text: "المرحلة الإعدادية",
              url: "https://www.tiktok.com/@elkheta.middle",
              type: "tiktok",
            },
            {
              text: "الأول الإعدادي",
              url: "https://www.youtube.com/channel/UC_rD4-Tgr_HOcIMKg3qMpHQ",
              type: "youtube",
            },
            {
              text: "الثاني الإعدادي",
              url: "https://www.youtube.com/channel/UCTLGSi_pWyjN4BVtkQb31ow",
              type: "youtube",
            },
            {
              text: "الثالث الإعدادي",
              url: "https://www.youtube.com/@elkheta-middle3",
              type: "youtube",
            },
          ],
        },
        {
          title: "قنوات التواصل لثانوى",
          forMobile: false,
          links: [
            {
              text: "المرحلة الثانوية",
              url: "https://www.facebook.com/elkheta0?mibextid=ZbWKwL",
              type: "facebook",
            },
            {
              text: "المرحلة الإعدادية",
              url: "https://www.instagram.com/el.kheta?igsh=MTM3eGRjcGRldWtwOQ==",
              type: "instagram",
            },
            {
              text: "المرحلة الثانوية",
              url: "https://www.tiktok.com/@el_kheta?_t=8oQoNlc4Jm5&_r=1",
              type: "tiktok",
            },
            {
              text: "الأول الثانوي",
              url: "https://www.youtube.com/channel/UCLXVLh8KSX1xPrQlNcuBhgA",
              type: "youtube",
            },
            {
              text: "الثاني الثانوي",
              url: "https://www.youtube.com/@elkheta-senior2/shorts",
              type: "youtube",
            },
            {
              text: "الثالث الثانوي",
              url: "https://www.youtube.com/channel/UCfpqV2ik8QfA7AZFWMa39wg",
              type: "youtube",
            },
          ],
        },
        {
          title: "الأحكام",
          forMobile: true,
          links: [
            {
              text: "سياسة الخصوصية",
              url: "/privacy-policy",
              type: null,
            },
            {
              text: "الشروط والأحكام",
              url: "/terms-of-use",
              type: null,
            },
            {
              text: "سياسة الاسترداد",
              url: "/refund-policy", 
              type: null,
            },
          ],
        },
        {
          title: "عامة",
          forMobile: true,
          links: [
            {
              text: "عن الخطة",
              url: "/about-us",
              type: null,
            },
            {
              text: "linkedin",
              url: "https://www.linkedin.com/company/elkheta?originalSubdomain=eg",
              type: null,
            },
          ],
        },
        
      ],
      policyLinks: [
        {
          text: "سياسة الخصوصية",
          url: "/privacy-policy",
        },
        {
          text: "الشروط والأحكام",
          url: "/terms-of-use",
        },
        {
          text: "سياسة الاسترداد",
          url: "/refund-policy",
        },
      ],
    };
  },
  components: {
    FooterSection,
    FooterLinksSection,
    CopyrightNotice,
  },
  computed: {
    filteredItems() {
      return this.items.filter(item => item.forMobile);
    },
  }
};
</script>

<style scoped>
.hr {
  border-top: 1px solid #707578;
  padding-bottom: 2%;
}
</style>
